@import 'variables'

.frame-1321313911
  align-items: center
  background-color: $blueberry
  border-radius: 16px
  box-shadow: 0px 4px 18px #5269e26b
  display: flex
  height: 42px
  left: 215px
  min-width: 73px
  padding: 0 12px
  position: absolute
  top: 257px

.free
  letter-spacing: 0
  line-height: 32px
  min-height: 32px
  min-width: 49px
  white-space: nowrap

.frame-1321313911.frame-1321313911-1,
.frame-1321313911.frame-1321313911-3
  left: 233px
  top: 0

.frame-1321313911.frame-1321313911-2
  align-self: flex-end
  left: unset
  margin-top: -21px
  position: unset
  top: unset

