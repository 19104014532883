@import 'variables'
@import 'mixins'
@import 'rem'

// .section-title
//     @include mobile
//         text-align: left !important
//         white-space: pre-line
//         align-self: flex-start
//         padding-left: 2rem !important



.container-contacts-staff
    justify-items: center
    margin: 0 auto
    display: grid
    padding: 0 0 2rem 0 
    .card
        text-align: center
        width: 100%
        .title
            font-weight: 600
            color: $black
            font-size: $font-size-m
        .subtitle, .text
            padding:0rem
            display: flex
            flex-direction: column
            span
                padding: 0rem
        .text
            padding-top: 0.5rem
            span
                font-size: $font-size-xs
    @include desktop
        max-width: rem(1180px)
        grid-template: repeat(3, rem(389px)) / repeat(4, 1fr)
        column-gap: 1rem
        row-gap: 1rem
        .card
            width: rem(272px)
            height: rem(389px)
            &:nth-child(9)
                grid-column: 2
    @include mobile
        grid-template-columns: repeat(1,1fr)
        row-gap: 1rem
        width: 100%
        align-items: center
