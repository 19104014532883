@import 'variables'

.group-1359902-1
  align-items: flex-start
  display: flex
  height: 203px
  left: 0
  min-width: 278px
  position: absolute
  top: 174px

.overlap-group1-33
  height: 232px
  margin-left: -15px
  margin-top: -14.69px
  position: relative
  width: 257px

.ellipse-115-2
  background-color: $white
  border-radius: 25.12px
  height: 50px
  left: 207px
  position: absolute
  top: 91px
  width: 50px

.frame-1321313961
  align-items: flex-start
  border-radius: 102.82px
  display: flex
  height: 232px
  left: 0
  overflow: hidden
  padding: 0.0px 0
  position: absolute
  top: 0
  width: 232px

.overlap-group-83
  background-position: 50% 50%
  background-size: cover
  height: 203px
  position: relative
  width: 203px

.rectangle-441-5
  height: 58px
  left: 13px
  position: absolute
  top: 145px
  width: 177px

.title-5
  left: 44px
  letter-spacing: 0
  position: absolute
  text-align: center
  top: 171px
  width: 128px

.ellipse-116-1
  align-self: center
  background-color: $white
  border-radius: 8.26px
  height: 17px
  margin-bottom: 0.92px
  margin-left: 19px
  opacity: 0.5
  width: 17px

