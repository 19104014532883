.rectangle-button-17
  align-items: flex-end
  background-size: 100% 100%
  display: flex
  height: 21px
  min-width: 21px
  padding: 6.5px 6.5px
  transform: rotate(-90.00deg)

.fontistokey-2
  height: 8px
  margin-bottom: 0
  width: 8px

.rectangle-button-17.rectangle-button-18
  justify-content: flex-end
  margin-left: 0
  margin-top: 8px
  padding: 6.0px 6.0px

.rectangle-button-17.rectangle-button-18 .fontistokey-2
  height: 9px
  margin-bottom: unset
  width: 9px

.rectangle-button-17.rectangle-button-16,
.rectangle-button-17.rectangle-button-16-1
  margin-left: 0
  margin-top: 8px

.rectangle-button-17.rectangle-button-16 .fontistokey-2
  margin-bottom: unset

