@import 'variables'

.screen
  padding-top: 4rem
  padding-bottom: 0
  @media screen and (max-width: 989px)
    .screen-title
      font-size: $font-size-xl

  .content-block
    padding-bottom: 0

.screen-content
  line-height: $font-size-xl
  b
    font-weight: 600

.feature-card
  position: relative
  height: 32rem
  .card-background
      width: 70%
      left: 25%
  &.no-bottom-borders
    border-bottom-left-radius: 0
    border-bottom-right-radius: 0
  &.qrideo
    background-size: 20rem
    background-position-y: 13rem
    background-position-x: 65%
    
  &.outdoor
    background-size: 20rem
    background-position-y: bottom
    background-position-x: 90%
    .content-wrapper
      .title 
        align-self: center
        line-height: $font-size-l
        padding: 0 0
      .content 
        padding: 0 0
      .feature-text b
        font-weight: 600
        color: $black
        padding: 1rem 0
      a
        position: absolute
        bottom: 1rem
        right: 2rem
  
.x1-page
  align-items: center
  background-size: 100% 100%
  display: flex
  flex-direction: column
  height: 958px
  padding: 24px 0

.frame-1321314063
  align-items: center
  display: flex
  height: 50px
  min-width: 1360px
  position: relative

.frame-1321314061-1
  height: 17px
  width: 192px

.frame-1321313956
  align-items: center
  display: flex
  flex-direction: column
  margin-top: 86px
  min-height: 774px
  width: 1160px

.frame-1321313953
  align-items: center
  display: flex
  flex-direction: column
  min-height: 224px
  width: 958px

.the-intercom-reimagined
  letter-spacing: 0
  min-height: 88px
  min-width: 958px
  text-align: center

.frame-1359886
  align-items: center
  display: flex
  flex-direction: column
  margin-top: 32px
  min-height: 104px
  width: 561px

.the-way-we-communica
  letter-spacing: 0
  line-height: 32px
  min-height: 64px
  min-width: 561px
  text-align: center

.lets-change-that
  letter-spacing: 0
  line-height: 32px
  margin-top: 8px
  min-height: 32px
  min-width: 166px
  text-align: center
  white-space: nowrap

.frame-1321313955
  align-items: flex-start
  display: flex
  margin-top: 64px
  min-width: 1160px

.frame-1321313939
  align-items: flex-end
  background-color: $white-2
  border-radius: 40px
  display: flex
  height: 486px
  overflow: hidden
  padding: 26.3px 32px
  width: 568px

.over-container
  height: 417px
  margin-left: 47px
  position: relative
  width: 457px

.overlap-group-5
  height: 417px
  left: 0
  position: absolute
  top: 0
  width: 410px

.frame-1321313937
  align-items: center
  display: flex
  flex-direction: column
  left: 0
  min-height: 140px
  position: absolute
  top: 0
  width: 410px

.qr-video-voice-text
  letter-spacing: 0
  line-height: 52px
  min-height: 52px
  min-width: 385px
  text-align: center
  white-space: nowrap

.qr-video-intercom-5
  letter-spacing: 0
  line-height: 32px
  margin-top: 12px
  min-height: 32px
  min-width: 177px
  text-align: center
  white-space: nowrap

.a-new-world-of-conne
  letter-spacing: 0
  line-height: 32px
  margin-top: 12px
  min-height: 32px
  min-width: 410px
  text-align: center
  white-space: nowrap

.group-1359886
  height: 287px
  left: 33px
  position: absolute
  top: 130px
  width: 286px

.discover
  left: 361px
  letter-spacing: 0
  line-height: 32px
  position: absolute
  top: 362px
  white-space: nowrap

.frame-1321313940
  align-items: flex-end
  background-color: $white-2
  border-radius: 40px
  display: flex
  height: 486px
  margin-left: 24px
  overflow: hidden
  padding: 0 32px
  width: 568px

.overlap-group2
  height: 612px
  margin-bottom: -169.39px
  margin-left: 4px
  position: relative
  width: 500px

.frame-1321313938
  align-items: center
  display: flex
  flex-direction: column
  left: 0
  min-height: 140px
  position: absolute
  top: 0
  width: 496px

.outdoor-display-5
  letter-spacing: 0
  line-height: 52px
  min-height: 52px
  min-width: 307px
  text-align: center
  white-space: nowrap

.outdoor-display-6
  letter-spacing: 0
  line-height: 32px
  margin-top: 12px
  min-height: 32px
  min-width: 151px
  text-align: center
  white-space: nowrap

.make-an-unforgettabl
  letter-spacing: 0
  line-height: 32px
  margin-top: 12px
  min-height: 32px
  min-width: 496px
  text-align: center
  white-space: nowrap

.discover-1
  left: 404px
  letter-spacing: 0
  line-height: 32px
  position: absolute
  top: 362px
  white-space: nowrap

.frame-1359979
  background-size: 100% 100%
  height: 440px
  left: 204px
  position: absolute
  top: 173px
  width: 267px

.frame-1359979-1
  height: 351px
  left: 30px
  position: absolute
  top: 43px
  width: 241px

.slide-169-14
  align-items: flex-start
  background-color: $black
  display: flex
  height: 145px
  left: 124px
  overflow: hidden
  position: absolute
  top: 299px
  transform: rotate(-90.00deg)
  width: 256px

.overlap-group1-2
  height: 183px
  margin-left: 8px
  margin-top: -42.75px
  position: relative
  width: 290px

.unsplash-6z-fvl4-yua-m
  height: 171px
  left: 34px
  object-fit: cover
  position: absolute
  top: 0
  width: 256px

.group-1359695
  align-items: flex-start
  display: flex
  height: 168px
  left: 64px
  min-width: 120px
  position: absolute
  top: 31px
  transform: rotate(-90.00deg)

.frame-1359702
  -webkit-backdrop-filter: blur(19.13px) brightness(100%)
  align-items: flex-start
  backdrop-filter: blur(19.13px) brightness(100%)
  background-color: $black-2
  border-radius: 4.59px
  display: flex
  flex-direction: column
  margin-left: -144px
  margin-top: -24.22px
  min-height: 168px
  padding: 15.4px 25.5px
  transform: rotate(90.00deg)
  width: 120px

.address
  align-self: center
  height: 5px
  letter-spacing: 0
  margin-left: 0.13px
  text-align: center
  width: 40px

.welcome-to-our-building
  height: 20px
  letter-spacing: 0
  margin-top: 18px
  text-align: center
  width: 69px

.circle-button
  align-items: flex-start
  align-self: flex-end
  display: flex
  height: 43px
  justify-content: center
  margin-bottom: 13.57px
  margin-left: 19px
  min-width: 43px
  transform: rotate(90.00deg)

.overlap-group-6
  background-color: $royal-blue-2
  border-radius: 22.62px
  height: 45px
  margin-top: -1px
  position: relative
  width: 45px

.oval-3
  background-color: $royal-blue
  border-radius: 16.9px/16.86px
  height: 34px
  left: 6px
  position: absolute
  top: 6px
  width: 34px

.combined-shape
  height: 12px
  left: 18px
  position: absolute
  top: 17px
  width: 9px

.group-1359696
  align-items: center
  display: flex
  height: 15px
  left: -53px
  min-width: 122px
  position: absolute
  top: 107px
  transform: rotate(-90.00deg)

.flex-col-3
  align-items: center
  align-self: flex-end
  display: flex
  flex-direction: column
  margin-bottom: -52.22px
  margin-left: -68px
  min-height: 58px
  width: 120px

.search
  height: 5px
  letter-spacing: 0
  margin-left: 22.19px
  text-align: center
  width: 14px

.rectangle-352
  -webkit-backdrop-filter: blur(19.13px) brightness(100%)
  backdrop-filter: blur(19.13px) brightness(100%)
  background-color: $black-2
  border-radius: 4.59px
  height: 15px
  margin-top: 37px
  transform: rotate(90.00deg)
  width: 120px

.akar-iconssearch
  align-items: flex-start
  display: flex
  height: 7px
  margin-left: 50px
  margin-top: 0
  padding: 0.6px 0.6px
  transform: rotate(90.00deg)
  width: 7px

.group
  height: 5px
  width: 5px

.intercom-info-plaque
  -webkit-backdrop-filter: blur(15.16px) brightness(100%)
  align-items: flex-start
  backdrop-filter: blur(15.16px) brightness(100%)
  background-color: $black-2
  border-radius: 4.52px
  display: flex
  height: 19px
  left: 160px
  min-width: 120px
  padding: 4.0px
  position: absolute
  top: 105px
  transform: rotate(-90.00deg)

.unsplasht2-sai-aq-ip-i
  height: 11px
  width: 11px

.group-1359704
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-left: 3px
  margin-top: 0.13px
  min-height: 10px
  width: 53px

.company-name
  height: 5px
  letter-spacing: 0

.new-york-ny-4715-5-avenue
  height: 4px
  letter-spacing: 0
  margin-left: 0
  margin-top: 1px

.phone
  letter-spacing: 0
  margin-left: 11px
  margin-top: 0.67px
  min-height: 4px
  min-width: 31px
  text-align: right

.group-1359703
  -webkit-backdrop-filter: blur(13.31px) brightness(100%)
  align-items: flex-start
  backdrop-filter: blur(13.31px) brightness(100%)
  background-color: $black-2
  border-radius: 4.52px
  display: flex
  flex-direction: column
  left: 18px
  min-height: 120px
  padding: 14.5px 3.7px
  position: absolute
  top: 55px
  width: 19px

.rectangle-button
  align-items: flex-start
  background-size: 100% 100%
  display: flex
  height: 12px
  justify-content: flex-end
  margin-left: 0
  margin-top: 4px
  min-width: 12px
  padding: 0.7px 0.8px
  transform: rotate(-90.00deg)

.icon-concierge
  height: 10px
  width: 10px

.logo-1-color
  align-items: flex-start
  display: flex
  flex-direction: column
  left: 226px
  min-height: 7px
  padding: 0.0px 0.0px
  position: absolute
  top: 164px
  transform: rotate(-90.00deg)
  width: 32px

.union
  height: 1px
  margin-left: 0.05px
  width: 18px

.subtract
  height: 4px
  margin-top: 1px
  width: 32px

