.frame-132131391-2
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-top: 16px
  min-height: 128px
  position: relative
  width: 117px

.frame-132131391-2.frame-1321313917-4
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-top: 31px
  min-height: 128px
  position: relative
  width: 117px

