
@import 'variables'
@import 'mixins'
@import 'rem'
    
.intercoms
    .content-screen
        padding-bottom: 0

.intercom-card
    position: relative
    height: calc(15rem + rem(320px))
    @include desktop
        width: rem(568px)
    .linksection
        position: absolute
        bottom: 2rem
        right: 2rem
        z-index: +1
    .content
        padding-bottom: 0rem
        h3
            color: $black !important
            font-weight: 600
            width: 100%
            flex-direction: column
        .text
            display: flex
            flex-direction: column
        .title, .subtitle, .text
            text-align: center !important
            width: 100%
        .image-component
            padding-bottom: 0rem
            height: rem(568px)
            position: absolute
            background-size: auto rem(568px)
            background-position-y: rem(150px)
            bottom: 0
            overflow: hidden
            margin-left: auto
            margin-right: auto
            left: 0
            right: 0