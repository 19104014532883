.frame-1321313959
  align-items: flex-start
  border-radius: 102.82px
  display: flex
  height: 188px
  left: 9px
  overflow: hidden
  padding: 0.0px 0.8px
  position: absolute
  top: 0
  width: 188px

.overlap-group-82
  background-image: url(/static/img/rectangle-4-9@2x.png)
  background-position: 50% 50%
  background-size: cover
  height: 159px
  position: relative
  width: 158px

.rectangle-441-3
  height: 49px
  left: 0
  position: absolute
  top: 110px
  width: 158px

.title-3
  left: 29px
  letter-spacing: 0
  position: absolute
  text-align: center
  top: 132px
  width: 100px

.frame-1321313959.frame-1321313959-1
  left: unset
  margin-left: -14.69px
  margin-top: -15px
  padding: 0 0.8px
  position: unset
  top: unset

.frame-1321313959.frame-1321313959-1 .overlap-group-82
  margin-top: 0

