@import 'variables'
@import 'mixins'
@import 'rem'

.feature-preview-card
    padding: 0 0 10rem 0
    .content
        position: relative
        height: 26rem

.feature-image
    width: 60% !important
    margin: 0 auto
    position: absolute
    align-self: center
    bottom: -10rem

.discover-features-screen
    .childnodes
        @include desktop
            grid-template-columns: repeat(3, 1fr) !important
            justify-items: center
            & > .card
                --stack-size: 3 !important

.column-card
    background-color: $white
    border-radius: rem(40px) 
    height: fit-content
    @include desktop
        display: grid
        grid-column-start: 1
        grid-column-end: 4
        width: 100%
        max-width: 100%
    @include mobile
        @include gragientBg($startcolor: $blueberry, $endcolor: $blueberry-dark)
        .title, .subtitle, .text, a
            color: $white !important
            text-align: left
        .subtitle
            display: flex
            flex-direction: column
            padding: 1rem 0

.award-logos
    display: grid
    width: 100%
    .content
        display: grid
        width: 100%
    @include desktop
        grid-column-start: 1
        grid-column-end: 4
        .content
            grid-template-columns: repeat(6, 1fr)
    
    @include mobile
        .content
            grid-template-columns: repeat(2, 1fr)
