@import 'variables'
@import 'mixins'
@import 'rem'
    
.outdoor-features
    .childnodes
        @include desktop
            grid-template-columns: repeat(3, 1fr) !important
            background: url('/static/img/outdoor6.png')
            background-repeat: no-repeat
            background-position-x: center
            background-position-y: bottom
            background-size: rem(660px)
            .card
                width: rem(371px)
                height: rem(576px)
                .content
                    justify-content: space-between
                &:nth-child(3)
                    .outdoor-feature
                        padding: 0 0 0 5rem
        @include mobile
            .card
                &:nth-child(2)
                    display: none
                width: 100%
                .content
                    padding: 0rem
                    width: 100%
                    .outdoor-feature
                        padding: 1rem
                        margin: 0.5rem 0
                        border-radius: rem(12px)
                        background-color: $white-2
