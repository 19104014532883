@import 'variables'

.frame-1321314062
  align-items: flex-start
  display: flex
  margin-bottom: -3px
  margin-left: 153px
  min-width: 288px
  position: relative

.button-3
  align-items: center
  background-color: $blueberry
  border-radius: 10px
  box-shadow: 0px 10px 25px #6980fe80
  display: flex
  height: 50px
  margin-left: 8px
  overflow: hidden
  padding: 0 23px
  width: 140px

.feedbacks-3
  height: 16px
  letter-spacing: 0
  line-height: 16px
  min-width: 94px
  text-align: center
  white-space: nowrap

.frame-1321314062.frame-1321314062-1
  margin-bottom: unset

.frame-1321314062.frame-1321314062-2,
.frame-1321314062.frame-1321314062-3,
.frame-1321314062.frame-1321314062-4,
.frame-1321314062.frame-1321314062-5,
.frame-1321314062.frame-1321314062-6,
.frame-1321314062.frame-1321314062-7,
.frame-1321314062.frame-1321314062-8,
.frame-1321314062.frame-1321314062-9,
.frame-1321314062.frame-1321314062-10,
.frame-1321314062.frame-1321314062-11,
.frame-1321314062.frame-1321314062-12,
.frame-1321314062.frame-1321314062-13,
.frame-1321314062.frame-1321314062-14,
.frame-1321314062.frame-1321314062-15,
.frame-1321314062.frame-1321314062-16
  align-self: flex-start
  margin-bottom: unset
  margin-top: -3px

.frame-1321314062.frame-1321314062-4 .button-3,
.frame-1321314062.frame-1321314062-15 .button-3
  min-width: 140px
  overflow: unset
  width: unset

