@import 'variables'

.hardware-2
  align-items: flex-end
  display: flex
  height: 179px
  left: 0
  min-width: 264px
  position: absolute
  top: 0

.flex-col-7
  align-items: flex-start
  display: flex
  flex-direction: column
  min-height: 125px
  width: 2px

.switch-2
  background-color: $black
  height: 19px
  opacity: 0.5
  transform: rotate(-180deg)
  width: 2px

.volume-up-button-2
  background-color: $black
  height: 37px
  margin-top: 20px
  opacity: 0.5
  transform: rotate(-180deg)
  width: 2px

.volume-down-button-2
  background-color: $black
  height: 37px
  margin-top: 12px
  opacity: 0.5
  transform: rotate(-180deg)
  width: 2px

.notch-4
  align-items: flex-start
  align-self: flex-start
  background-image: url(/static/img/notch-shape-4@2x.png)
  background-size: 100% 100%
  display: flex
  height: 13px
  justify-content: flex-end
  margin-left: 80px
  margin-top: 0
  min-width: 101px
  padding: 0 28.1px

.speaker-2
  background-color: $black
  border-radius: 81.95px
  height: 4px
  margin-top: 1.28px
  opacity: 0.75
  width: 27px

.camera-2
  height: 7px
  margin-left: 2px
  margin-top: 0
  width: 7px

.power-button-2
  background-color: $black
  height: 61px
  margin-bottom: 12.77px
  margin-left: 79px
  opacity: 0.5
  width: 2px

.hardware-2.hardware-3 .notch-4
  padding: 0.0px 28.1px

.hardware-2.hardware-3 .camera-2
  margin-top: unset

