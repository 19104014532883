@import 'mixins'
@import 'variables'
@import 'rem'

.column-card
    background-color: $white
    border-radius: rem(40px) 
    height: fit-content
    .content
    @include desktop
        display: grid
        grid-template-columns: 1fr 1fr
        width: 100%
        max-width: 100%
    @include mobile
        @include gragientBg($startcolor: $blueberry, $endcolor: $blueberry-dark)
        .title, .subtitle, .text, a
            color: $white !important
            text-align: left
        .subtitle
            display: flex
            flex-direction: column
            padding: 1rem 0

.block-actions
    padding: 2rem
    display: flex
    justify-self: end
    .component-button
        width: auto
        padding: 0 1rem
        align-self: flex-end
        &:nth-child(even)
            @include primaryColors
        &:nth-child(odd)
            @include secondaryColors
    @include mobile
        flex-direction: column
        .component-button
            margin: 0 0 0.5rem 0
            width: 100%
// .dealer-block
//     .content-wrapper
//         @include whiteblock
//         height: auto
//         width: 100%
//         @media screen and (min-width: 990px)
//             display: grid !important
//             grid-template-columns: repeat(2, 1fr)
//         @media screen and (max-width: 989px)
//             @include gragientBg($startcolor: $blueberry, $endcolor: $blueberry-dark)
//             color: $white

    
//         .block-actions
//             display: flex
//             justify-self: end
//             .component-button
//                 width: auto
//                 padding: 0 1rem
//                 &:nth-child(even)
//                     @include primaryColors
//                 &:nth-child(odd)
//                     @include secondaryColors

//                 @media screen and (max-width: 989px)
//                     margin: 0.5rem 0
//                     padding: 1.5rem 2rem
//                     border-radius: 0.5rem
//                     font-size: $font-size-s

//                     &:nth-child(even)
//                         @include secondaryColors                    
//                     &:nth-child(odd)
//                         @include blankColors

//             @media screen and (min-width: 990px)
//                 flex-direction: row
//                 align-items: flex-end
//                 justify-content: space-between
//                 height: 100%
//             @media screen and (max-width: 989px)
//                 flex-direction: column
//                 padding: 1rem 0

//         .block-description
//             .title
//                 font-size: $font-size-xl
//                 line-height: $font-size-xl
//                 font-weight: 700
//                 display: block
//                 width: 100%
//                 text-align: left
//                 padding: 0 0 1rem 0
//                 @media screen and (max-width: 989px)
//                     color: #fff
//                     font-size: $font-size-l
//                     line-height: $font-size-xl
//             .description
//                 font-size: $font-size-m
//                 @media screen and (max-width: 989px)
//                     color: #fff
//                     font-size: $font-size-s
//                     line-height: $font-size-m
//                     font-weight: 100
//             @media screen and (min-width: 990px)
//                 width: 90%
                