@import 'variables'

.footer-1
  align-items: flex-start
  background-color: $steel-gray
  display: flex
  flex-direction: column
  margin-right: 1.0px
  margin-top: 123px
  min-height: 467px
  padding: 20px 132px
  width: 1441px

.frame-1321314022-1
  align-items: flex-start
  display: flex
  height: 232px
  margin-left: 9px
  margin-top: 36px
  min-width: 1114px

.frame-1321314018-1
  align-items: flex-start
  display: flex
  flex-direction: column
  min-height: 184px
  width: 119px

.place-1,
.technologies-1,
.intercom-1,
.qr-video-intercom-1,
.surname-2,
.facilities-1
  height: 32px
  letter-spacing: 0
  line-height: 32px
  white-space: nowrap

.frame-1321314017-1
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-top: 24px
  min-height: 128px
  width: 119px

.awards-1,
.about-us-1,
.outdoor-display-1,
.hospitals-1,
.transport-1,
.higher-ed-1
  height: 32px
  letter-spacing: 0
  line-height: 32px
  margin-top: 16px
  white-space: nowrap

.frame-1321314020-1
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-left: 96px
  min-height: 136px
  width: 172px

.frame-1321314019-2
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-top: 24px
  min-height: 80px
  width: 172px

.video-concierge-4
  height: 32px
  letter-spacing: 0
  line-height: 32px
  margin-left: 96px
  min-width: 153px
  white-space: nowrap

.help-as-a-service-3
  height: 32px
  letter-spacing: 0
  line-height: 32px
  margin-left: 96px
  min-width: 162px
  white-space: nowrap

.frame-1321314021-1
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-left: 96px
  min-height: 232px
  width: 124px

.frame-1321314019-3
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-top: 24px
  min-height: 176px
  width: 95px

.group-1359927-1
  align-items: center
  background-color: $white-4
  border-radius: 16px
  display: flex
  height: 62px
  margin-left: 9px
  min-width: 198px
  padding: 8px 9px

.footer-1-1
  align-self: flex-end
  height: 45px
  object-fit: cover
  width: 45px

.scan-to-talk-to-us-live-or-click-here-1
  letter-spacing: 0
  line-height: 12.5px
  margin-bottom: 1.0px
  margin-left: 6px
  min-height: 33px
  min-width: 129px

.group-1359871
  align-items: flex-end
  align-self: flex-end
  display: flex
  height: 96px
  margin-top: 1px
  min-width: 1168px

.httpsqrideomeco
  letter-spacing: 0
  line-height: 24px
  min-height: 96px
  min-width: 197px

.flex-col-2
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-left: 17px
  min-height: 48px
  width: 154px

.privacy-policy
  letter-spacing: 0
  line-height: 24px
  min-height: 24px
  min-width: 96px
  text-align: right
  white-space: nowrap

.terms-and-conditions
  letter-spacing: 0
  line-height: 24px
  min-height: 24px
  min-width: 154px
  text-align: right
  white-space: nowrap

.website-by-inventri
  letter-spacing: 0
  line-height: 24px
  margin-left: 177px
  min-height: 96px
  min-width: 615px
  text-align: right

.footer-1.footer-2,
.footer-1.footer-3,
.footer-1.footer-4
  margin-right: unset
  margin-top: unset

.footer-1.footer-2 .httpsqrideomeco,
.footer-1.footer-3 .httpsqrideomeco,
.footer-1.footer-4 .httpsqrideomeco
  min-width: 198px

.footer-1.footer-2 .flex-col-2,
.footer-1.footer-3 .flex-col-2,
.footer-1.footer-4 .flex-col-2
  margin-left: 16px

.footer-1.footer-2 .website-by-inventri,
.footer-1.footer-3 .website-by-inventri,
.footer-1.footer-4 .website-by-inventri
  margin-left: 176px
  min-width: 616px

