@import 'variables'
@import 'mixins'

.outdoor
    .content
        justify-content: center

.outdoor-splash
    .splash-backdrop
        @include mobile
            background-position-y: bottom

.outdoor6
    background-position-x: 58%
    background-image: url('/static/img/outdoor6.png')
    background-repeat: no-repeat
    background-position-y: bottom

.outdoor-feature-screen
    display: flex
    flex-direction: column
    height: 100vh

.outdoor-feature-table
    height: 100%

.outdoor-feature-column
    display: flex
    flex-direction: column
    height: 100%
    justify-content: space-between
    &:first-child
        justify-self: start
    &:last-child
        justify-self: end

.outdoor-feature
    display: flex
    align-content: center
    div
        display: block
        line-height: $font-size-s
        font-size: $font-size-s
        margin: auto 0
        &:nth-child(1)
            padding-right: 1rem
        &:nth-child(2)
            max-width: 10rem
            