@import 'variables'

.x5-page5
  align-items: center
  background-size: 100% 100%
  display: flex
  flex-direction: column
  height: 900px
  padding: 24px 0
  position: relative

.frame-1321313897-1
  align-items: flex-end
  border-radius: 40px
  display: flex
  height: 648px
  margin-top: 80px
  overflow: hidden
  width: 1160px

.overlap-group2-13
  height: 1277px
  margin-bottom: -573.5px
  margin-left: -104px
  position: relative
  width: 1502px

.group-1359843-1
  height: 1277px
  left: 48px
  position: absolute
  top: 0
  width: 1453px

.rectangle-1
  height: 648px
  left: 104px
  mix-blend-mode: soft-light
  object-fit: cover
  position: absolute
  top: 56px
  width: 1160px

.ellipse-88-2
  height: 344px
  left: 0
  position: absolute
  top: 580px
  width: 344px

.frame-1321314151
  align-items: center
  display: flex
  flex-direction: column
  left: 196px
  min-height: 494px
  position: absolute
  top: 128px
  width: 984px

.frame-1321313991
  align-items: flex-start
  display: flex
  flex-direction: column
  min-height: 132px
  width: 545px

.help-as-a-service-28
  letter-spacing: 0
  line-height: 52px
  margin-left: 3.5px
  min-height: 52px
  text-align: center
  white-space: nowrap
  width: 538px

.create-your-own-as
  letter-spacing: 0
  line-height: 32px
  margin-top: 16px
  min-height: 64px
  min-width: 545px
  text-align: center

.group-1359967
  align-items: flex-end
  display: flex
  height: 338px
  margin-top: 24px
  // min-width: 984px

.group-container-13
  height: 302px
  position: relative
  width: 480px

.group-1359854
  align-items: center
  display: flex
  flex-direction: column
  left: 0
  min-height: 302px
  padding: 0 95.5px
  position: absolute
  top: 0
  width: 320px

.av-live-help-desk
  letter-spacing: 0
  line-height: 32px
  margin-left: 1.0px
  margin-top: -29px
  min-height: 32px
  min-width: 66px
  text-align: center
  white-space: nowrap

.frame-1321313985
  align-self: flex-end
  height: 128px
  margin-top: 18px
  width: 128px

.group-1359787-2
  height: 67px
  margin-left: 0.47px
  margin-top: 23px
  width: 67px

.group-1359851
  align-items: center
  display: flex
  flex-direction: column
  left: 252px
  min-height: 215px
  padding: 0 49.5px
  position: absolute
  top: 87px
  width: 228px

.av-live-help-desk-1
  letter-spacing: 0
  line-height: 32px
  margin-left: 1.0px
  margin-top: -29px
  min-height: 32px
  min-width: 114px
  text-align: center
  white-space: nowrap

.group-1359852
  align-items: center
  display: flex
  flex-direction: column
  margin-left: 24px
  min-height: 215px
  padding: 0 47px
  width: 228px

.av-live-help-desk-2
  align-self: flex-end
  letter-spacing: 0
  line-height: 32px
  margin-top: -29px
  min-height: 32px
  min-width: 133px
  text-align: center
  white-space: nowrap

.frame-1321313985-1
  height: 128px
  margin-left: 1.0px
  margin-top: 18px
  width: 128px

.frame-1321313985-2
  align-items: flex-start
  align-self: center
  border: 12px solid $white-5
  border-radius: 68px
  display: flex
  height: 152px
  margin-bottom: 60.0px
  margin-left: 62px
  overflow: hidden
  width: 152px

.overlap-group-58
  align-items: center
  background-size: 100% 100%
  display: flex
  height: 128px
  min-width: 129px
  padding: 0 1.0px

.your-own-as-a-service-offering
  letter-spacing: 0
  line-height: 32px
  min-height: 86px
  text-align: center
  width: 127px

