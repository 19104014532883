.frame-1321313917
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-top: 16px
  min-height: 128px
  position: relative
  width: 167px

.frame-1321313914
  align-items: center
  display: flex
  height: 32px
  justify-content: flex-end
  margin-top: 16px
  min-width: 167px

.group-11
  height: 22px
  margin-bottom: 0
  width: 20px

.voice-10-calls
  letter-spacing: 0
  line-height: 32px
  margin-left: 11px
  min-height: 32px
  min-width: 135px
  white-space: nowrap

.frame-1321313915
  align-items: center
  display: flex
  height: 32px
  justify-content: flex-end
  margin-top: 16px
  min-width: 165px

.group-12
  height: 14px
  margin-top: 0
  width: 19px

.video-10-calls
  letter-spacing: 0
  line-height: 32px
  margin-left: 10px
  min-height: 32px
  min-width: 133px
  white-space: nowrap

.frame-1321313917.frame-1321313917-2
  margin-left: 18px
  margin-top: 31px

