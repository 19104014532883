@import './variables'
@import './mixins'
@import './rem'

@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css")

@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700,500,600|Roboto:400,700,500")

@font-face
  font-family: "SF Pro Display-Thin"
  font-style: normal
  font-weight: 100
  src: url('static/fonts/SF-Pro-Display-Thin.otf') format("opentype")
@font-face
  font-family: "SF UI Display-Regular"
  font-style: normal
  font-weight: 400
  src: url('static/fonts/SF-UI-Display-Regular.otf') format("opentype")
@font-face
  font-family: "SF Pro-Regular"
  font-style: normal
  font-weight: 400
  src: url('static/fonts/SF-Pro.ttf') format("truetype")

/* The following line is used to measure usage of this code in production. For more info see our usage billing page */
@import url("https://px.animaapp.com/621ee35fb7b66445f330bc76.621ee3627008b16657f7b5f3.QTy5VBD.rsp.png")


body
  margin:0
  background: linear-gradient(109.75deg, #F4F4FC 2.14%, #E3E6F4 100%)
    
#app
  width: 100%
  height: 100%
  font-family: $font-family-montserrat
  display: grid
  
  @include mobile
    display: grid
    grid-template-columns: 1fr
    width: 100vw

  .section-title
    display: flex
    width: 100%
    text-align: center
    justify-content: center
    font-size: 32px
    padding: 2rem 0
    font-weight: 700

.modal-overlay
  z-index: 9999
  width: 100%
  height: 100%
  position: fixed
  left: 0
  top: 0
  font-family: $font-family-montserrat

.content-screen
  @include mobile
    min-height: 100vh
    padding: 0 1rem
    align-content: center
  padding: 0rem
  .screen-title
    font-size: $font-size-xl
    font-style: normal
    font-weight: 700
  
.screen
  display: flex
  align-content: center
  align-items: center
  justify-content: center
  justify-items: center
  padding: 2rem 0
  white-space: pre-line
  &:not(.fullwidth)
    width: 60%
  &.fullwidth
    width: 100%
  .screen-title
    font-size: $font-size-xl
    font-style: normal
    font-weight: 700
  .screen-subtitle
    font-size: $font-size-l
    font-style: normal
    font-weight: 700
    color: $steel-gray
    padding: 3rem 0 0 0 
  .screen-content
    color: $regent-gray
    font-family: $font-family-montserrat
    font-size: $font-size-m
    font-style: normal
    font-weight: 400
    padding: 1rem 0 0 0 
  &.column-layout
    @media screen and (min-width: 990px)
      display: flex
      flex-direction: column
      align-items: center
    @media screen and (max-width: 989px)
      display: grid
      grid-template-columns: 1fr


.screen a
  display: contents
  text-decoration: none

.container-content
  display: grid
  grid-auto-columns: 1fr
  padding-top: rem(82px)
  @include desktop
    grid-auto-rows: minmax(calc(100vh - 82px), auto)
  // height: calc(100vh - 82px)
  width: 100%
  height: 100%
  align-content: center
  align-items: center
  justify-content: center
  justify-items: center
  // overflow: hidden

  a
    text-decoration: none
    cursor: pointer
    font-weight: 600
    color: $blueberry
    
  .content-screen
    width: 100%
    align-items: center
    display: grid
    flex-direction: column

  &.withbg
    background-image: url('/static/img/backdrop.png')
    background-size: 100%
    background-repeat: no-repeat

  & > .backdrop
    width: 100%


  &.page-sized
    @include desktop
      height: calc(100vh - 82px)
      min-height: rem(858px)


  .content-block
    display: flex
    justify-content: center
    align-content: center
    justify-items: center
    padding: 4rem 0
    width: 100%

    @include desktop
      &.column-layout
        display: grid
        grid-template-columns: repeat(var(--columns), 1fr)
        align-items: end
        :nth-child(odd)
          justify-self: flex-end
        :nth-child(even)
          justify-self: flex-start

      flex-direction: row
    
    @include mobile
      padding: 0 0 1rem 0
      align-items: center
      &.column-layout
        display: grid
        grid-template-columns: 1fr
      display: flex
      flex-direction: column
      &.rl
        flex-direction: column-reverse


.container-center-horizontal
  display: flex
  flex-direction: row
  justify-content: center
  pointer-events: none
  width: 100%
  // overflow: hidden

.container-center-horizontal > *
  flex-shrink: 0
  pointer-events: auto

.valign-text-middle
  display: flex
  flex-direction: column
  justify-content: center

.contentitem
  width: 100% !important
  min-width: unset
  max-width: unset
  .item-text
    padding: 2rem 0 0 0
    line-height: $font-size-l
    font-size: $font-size-m
    color: $regent-gray

.button
  @include button
  

*
  box-sizing: border-box


.show-desktop
  @include mobile
    display: none !important

.show-mobile
  @include desktop
    display: none !important

.qrcode-withpopup, .interactive
  cursor: pointer

.youtube-link
  padding: 0 2rem
  display: flex
  line-height: $font-size-l
  &::before
    content: ' '
    display: block
    background-image: url('/static/img/playicon.png')
    width: $font-size-l
    height: $font-size-l
    background-size: contain
    background-position: center
    background-repeat: no-repeat  
    padding: 0 1rem

.inline-flex
  display: flex !important
  width: inherit
  height: fit-content
  justify-content: center
  @include mobile
    flex-direction: column
    justify-content: flex-start
    align-items: flex-start !important