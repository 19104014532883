@import '../../../variables'
 
.component-navmenu
    display: flex
    align-items: flex-start
    min-width: 572px
    justify-content: space-between

    // @media screen and (min-width: 990px)
    //     div:not(.mainmenu)
    //         display: none
    @media screen and (max-width: 989px)
        flex-direction: column


    a
        text-decoration: none

    .component-navmenu-item
        color: #4f4f6a
        
        
