.frame-1321313960
  align-items: flex-start
  border-radius: 102.82px
  display: flex
  height: 219px
  left: 11px
  overflow: hidden
  position: absolute
  top: 0
  width: 219px

.overlap-group-80
  height: 311px
  margin-left: -147px
  margin-top: -25.52px
  position: relative
  width: 417px

.rectangle-4-6
  height: 285px
  left: 0
  object-fit: cover
  position: absolute
  top: 26px
  width: 336px

.rectangle-2-1
  height: 284px
  left: 132px
  object-fit: cover
  position: absolute
  top: 0
  width: 286px

.rectangle-441-1
  height: 58px
  left: 148px
  position: absolute
  top: 157px
  width: 188px

.title-1
  left: 182px
  letter-spacing: 0
  position: absolute
  text-align: center
  top: 183px
  width: 119px

.frame-1321313960.frame-1321313960-1
  left: 0

