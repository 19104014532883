@import 'variables'

.multicolumn-navbar
    display: flex
    justify-content: space-between
    align-items: flex-start

    .multicolumn-navbar-column
        display: flex
        flex-direction: column

        a
            color: $regent-gray
            font-family: $font-family-montserrat
            font-size: $font-size-m
            font-style: normal
            font-weight: 400
            text-decoration: none
            line-height: 3rem

            &:first-child
                font-weight: bolder
            