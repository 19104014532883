@use 'sass:math'
@import './variables'
@import './breakpoints'


@mixin contentblock
    border-radius: 40px
    .content-wrapper
        padding: 2rem

@mixin whiteblock
    @include contentblock
    background-color: $white

@mixin button
    line-height: 1.2rem
    height: 1.2rem
    min-width: 15rem
    border-radius: 10px
    overflow: hidden
    cursor: pointer

@mixin gragientBg($startcolor, $endcolor)
    background: linear-gradient(180deg, $startcolor 0%, $endcolor 100%)

@mixin withShadow($shadowColor: $blueberry)
    box-shadow: 0px 10px 25px $shadowColor

@mixin primaryColors
    color: $white
    background-color: $blueberry

@mixin secondaryColors
    color: $blueberry
    background-color: $white

@mixin blankColors
    color: $white
    background-color: $white-5

@mixin fullScreenBackdrop($bgUrl)
    $url:"#{$bgUrl}"
    background-image: url($url)
    background-size: 100vh
    min-height: rem(920px)
    background-repeat: no-repeat
    background-position-y: bottom
    background-position-x: center


@mixin rotateAndOffset($angle, $x:0, $y:0, $length)
    transform: rotate($angle)  translate($x, $y)
    -webkit-transform: rotate($angle)  translate($x, $y)
    -moz-transform: rotate($angle)  translate($x, $y)


@mixin mobile
    @media screen and (max-width: #{$desktop-width - 1px})
        @content

@mixin desktop
    @media screen and (min-width: $desktop-width)
        @content
    
@mixin pageTitle
    @include desktop
        font-size: $font-size-xxl !important
    @include mobile
        font-size: $font-size-xl
    font-weight: 700
    color: $steel-gray

@function getGridCardSize($stackSize:1 )
    @return calc( ($card-base-width + 2rem) * 2 / $stackSize )