@import 'variables'

.qrideofullscreen
  width: 100%
  .semitransparent
    background-color: $white-2

  @media screen and (min-width: 990px)
    background-image: url('/static/img/fonecom4visual.png')
    background-repeat: no-repeat
    background-size: cover

.frame-1359889
  height: 30rem
  display: flex
  flex-direction: column
  justify-content: space-evenly