@import 'variables'
@import 'rem'
    
.genericscreen
    align-items: start !important
    column-gap: 2rem  
    position: relative
    
.fullsize
    // width: 100%
    // height: 100%
    @media screen and (min-width: 990px)
        width: rem(576px)
        height: rem(576px)
    @media screen and (max-width: 989px)
        min-height: 10rem
        height: fit-content
    .title
        text-align: left
        word-break: break-word
        white-space: pre-line
        width: 100%
        align-self: flex-start
    .content
        padding-top: 1rem
        .contentcard-content
            text-align: left

.screen-overlay
    position: absolute
    height: auto
    width: auto
    margin: auto
    align-self: center
    z-index: +10
    &.bottom-root
        bottom: 0
    &.top-root
        top: 0
    &.left-root
        left: 0
    &.right-root
        right: 0