.frame-1321313913,
.frame-1321313913-1,
.frame-1321313913-2,
.frame-1321313913-3
  align-items: center
  display: flex
  height: 32px
  justify-content: flex-end
  min-width: 117px

.group-19,
.group-20,
.group-21,
.group-22
  height: 19px
  margin-top: 0.13px
  width: 19px

.text-chat,
.text-chat-1,
.text-chat-2,
.text-chat-3
  letter-spacing: 0
  line-height: 32px
  margin-left: 10px
  min-height: 32px
  min-width: 85px
  white-space: nowrap

