.frame-1321313898-3
  align-items: flex-start
  display: flex
  flex-direction: column
  min-height: 247px
  width: 307px

.video-concierge-3
  letter-spacing: 0
  line-height: 52px
  min-height: 104px
  width: 307px

.no-more-staff-shorta-1
  letter-spacing: 0
  line-height: 32px
  margin-top: 20px
  min-height: 123px
  width: 307px

.frame-1321313898-3.frame-1321313898-4
  min-height: 245px

.frame-1321313898-3.frame-1321313898-4 .video-concierge-3
  width: unset

.frame-1321313898-3.frame-1321313898-4 .no-more-staff-shorta-1
  min-height: 121px

