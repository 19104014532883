.mobile-menu
  align-items: center
  display: flex
  height: 24px
  left: 203px
  min-width: 327px
  position: absolute
  top: 98px

.fill-24
  height: 17px
  width: 192px

.cihamburger
  height: 24px
  margin-left: 111px
  width: 24px

.mobile-menu.mobile-menu-1,
.mobile-menu.mobile-menu-2,
.mobile-menu.mobile-menu-3,
.mobile-menu.mobile-menu-4,
.mobile-menu.mobile-menu-5,
.mobile-menu.mobile-menu-6,
.mobile-menu.mobile-menu-7,
.mobile-menu.mobile-menu-8,
.mobile-menu.mobile-menu-9,
.mobile-menu.mobile-menu-10,
.mobile-menu.mobile-menu-11,
.mobile-menu.mobile-menu-12,
.mobile-menu.mobile-menu-13,
.mobile-menu.mobile-menu-14,
.mobile-menu.mobile-menu-15,
.mobile-menu.mobile-menu-16,
.mobile-menu.mobile-menu-17,
.mobile-menu.mobile-menu-18,
.mobile-menu.mobile-menu-21,
.mobile-menu.mobile-menu-22,
.mobile-menu.mobile-menu-23,
.mobile-menu.mobile-menu-24,
.mobile-menu.mobile-menu-25,
.mobile-menu.mobile-menu-26,
.mobile-menu.mobile-menu-28,
.mobile-menu.mobile-menu-29,
.mobile-menu.mobile-menu-30,
.mobile-menu.mobile-menu-32,
.mobile-menu.mobile-menu-33,
.mobile-menu.mobile-menu-34
  height: unset
  left: unset
  position: unset
  top: unset

.mobile-menu.mobile-menu-19,
.mobile-menu.mobile-menu-20,
.mobile-menu.mobile-menu-31
  align-self: center
  height: unset
  left: unset
  position: unset
  top: unset

.mobile-menu.mobile-menu-27
  left: 322px
  top: 436px

.mobile-menu.mobile-menu-35
  height: unset
  left: unset
  margin-top: 22px
  position: unset
  top: unset

