.system-bar-white,
.system-bar-white-1
  align-items: center
  display: flex
  height: 11px
  left: 26px
  min-width: 219px
  position: absolute
  top: 9px

.time,
.time-1
  letter-spacing: -0.25px
  min-height: 11px
  text-align: center
  width: 34px

.cellular-connection,
.cellular-connection-1
  height: 7px
  margin-left: 140px
  margin-top: 0
  width: 11px

.wifi,
.wifi-1
  height: 7px
  margin-bottom: 0.22px
  margin-left: 3px
  width: 10px

.battery,
.battery-1
  height: 7px
  margin-left: 3px
  margin-top: 0
  width: 16px

