@import 'rem'

$apple: #43ce26
$apple-2: #43ce264c
$black: #000000
$black-2: #00000033
$blueberry: #6980fe
$blueberry-dark: #5768E6
$buttercup: #f0bf15
$cadet-blue: #acb1c64c
$cadet-blue-2: #acb1c6
$cadet-blue-3: #acb1c633
$fiord: #4f4f6a
$fiord-2: #4e4f6b
$french-gray: #bbc0cb
$lightning-yellow: #f0c0154c
$manatee: #898b9d
$mine-shaft: #333333
$mirage: #191c23
$regent-gray: #828c9e
$rose: #f55151
$royal-blue: #536ae2
$royal-blue-2: #536ae233
$shark: #25272f
$shark-2: #25252599
$snuff: #dde0ec
$snuff-2: #dee0ed
$snuff-3: #dfe0ee
$star-dust: #9b9b9b
$steel-gray: #212632
$steel-gray-2: #2126324c
$steel-gray-3: #21263208
$tuna: #2e2f474c
$white: #ffffff
$white-2: #ffffff99
$white-3: #ffffff80
$white-4: #ffffff0d
$white-5: #ffffff33
$gray: #828C9E


$card-base-width: rem(568px)

$font-size-l: rem(28px)
$font-size-m: rem(18px)
$font-size-s: rem(16px)
$font-size-xl: rem(36px)
$font-size-xxl: rem(64px)
$font-size-xs: rem(14px)
$font-size-xxs: rem(5.3px)
$font-size-xxxs: rem(2px)
$font-family-montserrat: "Montserrat", Helvetica
$font-family-roboto: "Roboto", Helvetica
$font-family-sf_pro-regular: 'SF Pro-Regular', Helvetica
$font-family-sf_pro_display-thin: 'SF Pro Display-Thin', Helvetica
$font-family-sf_pro_text-regular: 'SF Pro Text-Regular', Helvetica
$font-family-sf_pro_text-semibold: 'SF Pro Text-SemiBold', Helvetica
$font-family-sf_ui_display-regular: 'SF UI Display-Regular', Helvetica