@import 'variables'
@import 'mixins'
@import 'rem'

.collaboration-features
  padding: 2rem 0
  .childnodes .card
    @include desktop
      height: rem(456px)
      width: rem(371px)
  @include mobile
    padding-left: 1rem
    padding-right: 1rem


.teamservice-list
  display: flex
  padding: 0rem
  height: 100%
  @include mobile
    width: 100%
    height: fit-content
  .content
    justify-content: space-between !important
    padding: 0rem  
    .service
      display: flex
      border-radius: rem(40px)
      background-color: $white-2
      height: 6rem
      padding: 2rem
      width: 100%
      justify-items: center
      align-items: center
      align-content: center
      justify-content: center
      @include mobile
        margin: 0 0 1rem 0

      div
        width: 2rem
        margin: 0 1rem 0 0
      div, span
        height: 2rem
        line-height: 2rem
        display: flex

.x3-page7
  align-items: center
  background-size: 100% 100%
  display: flex
  flex-direction: column
  height: 100%
  padding: 24px 0
  position: relative
  .content
    align-items: flex-start
    display: flex
    flex-direction: column
    height: 118px
    left: 35px
    padding: 4.2px 5.4px
    position: absolute
    top: 9px
    width: 168px


.frame-1321313975
  align-items: center
  display: flex
  flex-direction: column
  margin-top: 90px
  min-height: 656px
  position: relative
  width: 1160px

.frame-1321313974
  display: grid
  @include desktop
    grid-template-columns: repeat(3, 1fr)
  @include mobile
    height: 100%
    grid-template-rows: repeat(auto-fit, 1fr)

.frame-1359897
  align-items: flex-end
  background-color: $white
  border-radius: 40px
  display: flex
  flex-direction: column
  min-height: 456px
  padding: 48px 17.7px
  width: 371px

.web-mobile-or-tablet-device
  align-self: center
  letter-spacing: 0
  line-height: 32px
  min-height: 32px
  min-width: 267px
  text-align: center
  white-space: nowrap

.overlap-group15
  height: 174px
  margin-top: 104px
  position: relative
  width: 336px

.overlap-group13
  height: 171px
  left: 0
  position: absolute
  top: 0
  width: 322px

.rectangle-432
  background-color: $tuna
  filter: blur(46.57px)
  
  height: 116px
  left: 59px
  position: absolute
  top: 6px
  width: 199px

.group-1359868
  align-items: flex-start
  background-size: 100% 100%
  display: flex
  height: 171px
  left: 0
  min-width: 322px
  padding: 10.2px 58.2px
  position: absolute
  top: 0

.screens
  align-items: flex-start
  background-size: 100% 100%
  display: flex
  flex-direction: column
  height: 137px
  overflow: hidden
  position: relative
  width: 203px

.agenda
  letter-spacing: 0.03px
  margin-left: -158.82px
  margin-top: -19px
  min-height: 2px
  text-align: center
  width: 7px

.overlap-group12
  height: 137px
  margin-top: 17px
  position: relative
  width: 203px

.flex-row-4
  align-items: flex-end
  display: flex
  height: 35px
  min-width: 157px
  position: relative

.flex-col-10
  align-items: flex-start
  display: flex
  flex-direction: column
  min-height: 35px
  width: 30px

.location-2
  letter-spacing: 0
  min-height: 4px
  width: 26px

.location-3
  letter-spacing: 0.03px
  margin-top: 2px
  min-height: 2px
  text-align: center
  width: 9px

.info
  -webkit-backdrop-filter: blur(5.64px) brightness(100%)
  align-items: flex-start
  backdrop-filter: blur(5.64px) brightness(100%)
  background-color: $shark
  border-radius: 3.38px
  display: flex
  flex-direction: column
  margin-left: 0
  margin-top: 3px
  min-height: 24px
  padding: 2.3px 0.8px
  width: 30px

.flex-row-5
  align-items: flex-start
  display: flex
  margin-left: 1.41px
  min-width: 26px

.status-online
  align-items: flex-end
  background-color: $apple-2
  border-radius: 1.13px
  display: flex
  height: 4px
  margin-top: 0
  min-width: 8px
  padding: 0.8px 0.1px

.location-12
  letter-spacing: 0
  min-height: 2px
  min-width: 6px
  text-align: center

.icon-more
  height: 5px
  margin-left: 14px
  width: 5px

.name-3
  height: 3px
  margin-left: 1.41px
  margin-top: 2px
  width: 25px

.icons
  align-items: flex-start
  display: flex
  margin-left: 1.41px
  margin-top: 6px
  min-width: 25px

.overlap-group-19
  border-radius: 1.13px
  height: 4px
  position: relative
  width: 4px

.rectangle-copy
  -webkit-backdrop-filter: blur(5.64px) brightness(100%)
  backdrop-filter: blur(5.64px) brightness(100%)
  background-color: $cadet-blue
  border-radius: 1.13px
  height: 4px
  left: 0
  position: absolute
  top: 0
  transform: rotate(-90deg)
  width: 4px

.overlap-group-20
  border-radius: 1.13px
  height: 4px
  margin-left: 1px
  position: relative
  width: 4px

.icon
  height: 2px
  left: 1px
  position: absolute
  top: 1px
  width: 2px

.icon-audio
  background-size: 100% 100%
  height: 2px
  left: 1px
  position: absolute
  top: 1px
  width: 2px

.overlap-group1-13
  height: 4px
  margin-left: 1px
  position: relative
  width: 7px

.rectangle-copy-4
  -webkit-backdrop-filter: blur(5.64px) brightness(100%)
  backdrop-filter: blur(5.64px) brightness(100%)
  background-color: $cadet-blue
  border-radius: 1.13px
  height: 7px
  left: 2px
  position: absolute
  top: -2px
  transform: rotate(-90deg)
  width: 4px

  .text
    left: 4px
    letter-spacing: 0
    position: absolute
    top: 1px
    width: 2px

.info-1
  -webkit-backdrop-filter: blur(5.64px) brightness(100%)
  align-items: flex-end
  backdrop-filter: blur(5.64px) brightness(100%)
  background-color: $shark
  border-radius: 3.38px
  display: flex
  flex-direction: column
  margin-left: 3px
  min-height: 24px
  padding: 2.3px 0.8px
  width: 30px

.flex-row-3
  align-items: flex-start
  display: flex
  min-width: 26px

.status-online-1
  align-items: flex-end
  background-color: $lightning-yellow
  border-radius: 1.13px
  display: flex
  height: 4px
  justify-content: flex-end
  margin-top: 0
  min-width: 6px
  padding: 0.8px 0.4px

.location-12-1
  letter-spacing: 0
  min-height: 2px
  min-width: 5px
  text-align: center

.icon-more-1
  height: 5px
  margin-left: 16px
  width: 5px

.name-2
  height: 3px
  margin-right: 1.41px
  margin-top: 2px
  width: 25px

.icons-1
  align-items: flex-start
  display: flex
  margin-right: 1.41px
  margin-top: 6px
  min-width: 16px

.info-2
  -webkit-backdrop-filter: blur(5.64px) brightness(100%)
  align-items: flex-end
  backdrop-filter: blur(5.64px) brightness(100%)
  background-color: $shark
  border-radius: 3.38px
  display: flex
  flex-direction: column
  margin-left: 2px
  min-height: 24px
  padding: 2.3px 0.8px
  width: 30px

.icons-2
  align-items: flex-start
  display: flex
  margin-right: 1.41px
  margin-top: 6px
  min-width: 21px

.hide-buttons
  align-self: center
  height: 5px
  margin-right: 3.66px
  margin-top: 9px
  width: 5px

.rectangle-305
  background-color: $blueberry
  height: 1px
  left: 36px
  position: absolute
  top: 8px
  width: 9px

.locations-menu-gray
  align-items: flex-start
  background-color: $cadet-blue-3
  display: flex
  height: 128px
  left: 0
  min-width: 35px
  padding: 3.4px 1.4px
  position: absolute
  top: 9px

.locations-menu
  align-items: flex-start
  display: flex
  flex-direction: column
  height: 45px
  overflow: hidden
  position: relative
  width: 32px

.flex-row-6
  align-items: center
  display: flex
  margin-left: 1.41px
  min-width: 30px

.locations
  letter-spacing: 0
  margin-bottom: 0.38px
  min-height: 3px
  min-width: 13px

.button-add
  align-items: flex-start
  display: flex
  margin-left: 13px
  overflow: hidden
  width: 4px

.overlap-group-21
  border-radius: 1.41px
  height: 4px
  margin-top: 0
  position: relative
  width: 4px

.rectangle-8-copy
  background-color: $white
  border-radius: 1.41px
  box-shadow: 0px 10px 20px #828c9e66
  height: 4px
  left: 0
  position: absolute
  top: 0
  transform: rotate(-180deg)
  width: 4px

.group-78
  height: 1px
  left: 1px
  position: absolute
  top: 1px
  width: 1px

.overlap-group1-14
  align-items: flex-start
  background-size: 100% 100%
  display: flex
  height: 6px
  justify-content: flex-end
  margin-left: 0
  margin-top: 3px
  min-width: 32px
  padding: 2.1px 0.8px

.location-1
  letter-spacing: 0
  margin-top: 0
  min-height: 2px
  width: 19px

.icon-arrow-down
  height: 2px
  margin-left: 9px
  width: 2px

.flat-i-pad
  height: 141px
  left: 234px
  position: absolute
  top: 33px
  width: 102px

.overlap-group14
  height: 120px
  left: 171px
  position: absolute
  top: 52px
  width: 63px

.black
  height: 112px
  left: 7px
  position: absolute
  top: 7px
  width: 55px

.group-33
  height: 112px
  left: 0
  position: absolute
  top: 0
  width: 55px

.rectangle-20
  height: 111px
  left: 1px
  position: absolute
  top: 1px
  width: 53px

.group-10-1
  height: 27px
  left: 0
  position: absolute
  top: 9px
  width: 55px

.subtract-5,
.group-11-1
  height: 107px
  left: 3px
  position: absolute
  top: 3px
  width: 49px

.group-9-1
  height: 2px
  left: 25px
  position: absolute
  top: 3px
  width: 9px

.group-103
  align-items: flex-start
  display: flex
  flex-direction: column
  left: 4px
  min-height: 12px
  position: absolute
  top: 73px
  width: 50px

.flex-row-7
  align-items: flex-start
  display: flex
  height: 5px
  min-width: 45px

.you
  letter-spacing: 0
  line-height: 2.6px
  min-height: 3px
  opacity: 0.6
  white-space: nowrap
  width: 3px

.yes-sure-how-can-i
  letter-spacing: 0
  line-height: 2.6px
  margin-left: 6px
  min-height: 5px
  width: 36px

.group-1359834
  align-items: flex-start
  display: flex
  height: 5px
  margin-top: 2px
  min-width: 50px

.client
  letter-spacing: 0
  line-height: 2.6px
  min-height: 3px
  opacity: 0.6
  white-space: nowrap
  width: 8px

.yes-im-one-of-the-staff-at-acme-inc
  letter-spacing: 0
  line-height: 2.6px
  margin-left: 1px
  min-height: 5px
  width: 37px

.frame-1359896
  align-items: center
  background-color: $white
  border-radius: 40px
  display: flex
  flex-direction: column
  margin-left: 24px
  min-height: 456px
  padding: 48px 0
  width: 371px

.teleportivity-cloud
  letter-spacing: 0
  line-height: 32px
  min-height: 32px
  text-align: center
  white-space: nowrap
  width: 239px

.group-1359898
  height: 154px
  margin-top: 115px
  width: 224px

