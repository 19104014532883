.burger
  align-items: flex-start
  background-size: 100% 100%
  display: flex
  height: 812px
  padding: 0 24px
  width: 375px

.frame-1321314022-5
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-top: 33px
  min-height: 672px
  width: 172px

.frame-1321314018-5
  align-items: flex-start
  display: flex
  flex-direction: column
  min-height: 160px
  width: 119px

.place-5,
.technologies-5,
.intercom-5,
.qr-video-intercom-6,
.surname-6,
.facilities-5
  height: 32px
  letter-spacing: 0
  line-height: 32px
  white-space: nowrap

.frame-1321314017-5
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-top: 16px
  min-height: 112px
  width: 119px

.awards-5,
.about-us-5,
.outdoor-display-7,
.hospitals-5,
.transport-5,
.higher-ed-5
  height: 32px
  letter-spacing: 0
  line-height: 32px
  margin-top: 8px
  white-space: nowrap

.frame-1321314020-5
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-top: 32px
  min-height: 120px
  width: 172px

.frame-1321314019-10
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-top: 16px
  min-height: 72px
  width: 172px

.frame-1321314022-item-1
  height: 32px
  letter-spacing: 0
  line-height: 32px
  margin-top: 32px
  white-space: nowrap

.frame-1321314021-5
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-top: 32px
  min-height: 200px
  width: 124px

.frame-1321314019-11
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-top: 16px
  min-height: 152px
  width: 95px

.codiconchrome-close
  height: 24px
  margin-left: 131px
  margin-top: 32px
  width: 24px

