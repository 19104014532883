.frame-1359884-2,
.frame-1359884-3,
.frame-1359884-4,
.frame-1359884-5,
.frame-1359884-6,
.frame-1359884-7,
.frame-1359884-8,
.frame-1359884-9,
.frame-1359884-10,
.frame-1359884-11,
.frame-1359884-12,
.frame-1359884-13,
.frame-1359884-14,
.frame-1359884-15,
.frame-1359884-16
  align-items: flex-start
  display: flex
  height: 32px
  margin-left: 153px
  min-width: 574px

.intercoms-4,
.intercoms-5,
.intercoms-6,
.intercoms-7,
.intercoms-8,
.intercoms-9,
.intercoms-10,
.intercoms-11,
.intercoms-12,
.intercoms-13,
.intercoms-14,
.intercoms-15,
.intercoms-16,
.intercoms-17,
.intercoms-18
  letter-spacing: 0
  line-height: 32px
  min-height: 32px
  min-width: 72px
  white-space: nowrap

.video-concierge-8,
.video-concierge-9,
.video-concierge-10,
.video-concierge-11,
.video-concierge-12,
.video-concierge-13,
.video-concierge-14,
.video-concierge-15,
.video-concierge-16,
.video-concierge-17,
.video-concierge-18,
.video-concierge-19,
.video-concierge-20,
.video-concierge-21,
.video-concierge-22
  letter-spacing: 0
  line-height: 32px
  margin-left: 62px
  min-height: 32px
  min-width: 116px
  white-space: nowrap

.help-as-a-service-7,
.help-as-a-service-8,
.help-as-a-service-9,
.help-as-a-service-10,
.help-as-a-service-11,
.help-as-a-service-12,
.help-as-a-service-13,
.help-as-a-service-14,
.help-as-a-service-15,
.help-as-a-service-16,
.help-as-a-service-17,
.help-as-a-service-18,
.help-as-a-service-19,
.help-as-a-service-20,
.help-as-a-service-21
  letter-spacing: 0
  line-height: 32px
  margin-left: 62px
  min-height: 32px
  min-width: 122px
  white-space: nowrap

.contact-us-2,
.contact-us-3,
.contact-us-4,
.contact-us-5,
.contact-us-6,
.contact-us-7,
.contact-us-8,
.contact-us-9,
.contact-us-10,
.contact-us-11,
.contact-us-12,
.contact-us-13,
.contact-us-14,
.contact-us-15,
.contact-us-16
  letter-spacing: 0
  line-height: 32px
  margin-left: 62px
  min-height: 32px
  white-space: nowrap

