@import 'variables'
@import 'mixins'
@import 'rem'

.interactive
    .title
        cursor: pointer

.card
    display: flex
    flex-direction: column
    color: $gray
    position: relative
    @include desktop
        height: rem(576px)
        width: getGridCardSize(var(--stack-size))
        grid-row: auto !important
    @include mobile
        max-width: rem(568px)

    img
        height: 100%

    &.centered-content-card
        .content
            justify-content: center


    .content
        display: flex
        flex-direction: column
        position: relative
        width: 100%
        height: 100%
        padding: 2rem
        z-index: +1
        & > .image-component
            width: 100%
        & > :not(:last-child)
            padding: 0 0 2rem 0
        
        & > ::last-child
            padding: 0
        ul li
            list-style-type: disc
            line-height: $font-size-l
            font-size: $font-size-m
        .subtitle
            width: 100%
            line-height: $font-size-l
            font-size: $font-size-m
        .text
            width: 100%
            span
                line-height: $font-size-m
                font-size: $font-size-s
        .subtitle, .text
                display: flex
                width: 100%
                color: $regent-gray
                & > * 
                    padding: 0 calc($font-size-m / 2)
                    justify-self: center
                    align-self: center

        .title
            color: $black
            font-weight: 700
            font-size: $font-size-xl

    .background
        max-width: rem(640px)
        max-height: rem(640px)
        margin: 0 auto
        padding: 1rem
        @include desktop
            height: 100%
            width: inherit
            position: absolute
            padding: 0
            bottom: 0

.card-bg-white
    .content
        border-radius: rem(40px)
        background-color: $white-2