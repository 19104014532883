@import 'variables'

.frame-1091343
  align-items: flex-start
  display: flex
  height: 231px
  margin-right: 0.3px
  margin-top: 30px
  overflow: hidden
  width: 271px

.overlap-group-3
  height: 233px
  margin-left: -1px
  margin-top: -2.2px
  position: relative
  width: 275px

.rectangle-363
  background-color: $steel-gray
  filter: blur(3.3px)
  
  height: 4px
  left: 76px
  position: absolute
  top: 228px
  width: 120px

.monitor
  height: 232px
  left: 0
  position: absolute
  top: 0
  width: 275px

.rectangle-4
  height: 151px
  left: 2px
  position: absolute
  top: 14px
  width: 268px

.frame-1091343.frame-1091343-1
  margin-top: unset

