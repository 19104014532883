.frame-1321313915-5-1
  align-items: center
  display: flex
  height: 32px
  justify-content: flex-end
  margin-top: 16px
  min-width: 84px

.group-27
  height: 14px
  margin-top: 0
  width: 19px

// .video
//   letter-spacing: 0
//   line-height: 32px
//   margin-left: 10px
//   min-height: 32px
//   min-width: 52px
//   white-space: nowrap

.frame-1321313915-5-1.frame-1321313915-6-3 .group-27,
.frame-1321313915-5-1.frame-1321313915-6 .group-27
  margin-bottom: 0
  margin-top: unset

