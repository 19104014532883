@import 'variables'
@import 'mixins'
@import 'rem'

.concierge-collaboration
    @include mobile
        padding-bottom: 4rem
        .card.content
            height: rem(480px)

.concierge-proactive
    @include mobile
        padding-bottom: 4rem
        .card .content
            height: rem(480px)

.concierge-web
    @include mobile
        padding-bottom: 4rem
        .card .content
            height: rem(420px)

.concierge-new-features
    @include mobile
        padding-bottom: 4rem
        .card .content
            height: rem(440px)


.concierge-offers-screen
    @include mobile
        padding-bottom: 2rem
        background-image: url('/static/img/concierge-offers-bg.png')

.videoconcierge
    .card
        @include mobile
            min-height: rem(480px)

.canihelpyou
    z-index: 999
    background-size: rem(200px)
    background-position-y: 8rem
    pointer-events: none
    position: absolute
    right: -50%
    top: -1rem

.concierge-cardbg
    position: absolute
    width: inherit
    background-clip: padding-box
    height: 100%
    bottom: 0
    left: 0
    @include desktop
        max-height: rem(400px)
    @include mobile
        background-position-y: bottom
        min-height: rem(371px)
        height: inherit

.top-content
    justify-content: flex-start
    .title,
    .content
        text-align: center
        width: 70%
        margin: 0 auto
        div
            text-align: center !important


.concierge5-arrow
    z-index: 999
    $length: 16rem
    width: $length
    height: 1px
    background-color: $gray
    @include mobile
        display: hidden
    &.top-arrow
        @include rotateAndOffset($angle: -20deg, $x: 33rem, $y: 30rem, $length: $length)
    &.middle-arrow
        width: 22rem
        @include rotateAndOffset($angle: 10deg, $x: 45rem, $y: 15rem, $length: $length)
    &.lower-arrow
        width: 12rem
        @include rotateAndOffset($angle: 30deg, $x: 47.5rem, $y: 1rem, $length: $length)
        
.concierge-pricingplans
    width: 100%
    display: flex
    flex-direction: column
    align-items: flex-start
    font-size: $font-size-s
    ul
        list-style-type: disc
        text-align: left
    .purchase-links
        display: grid
        grid-template-columns: repeat(4, 1fr)
        width: 100%
    .purchase-links a, .subscriptionlength
            display: block
    .purchase-links a
        &:nth-child(2)
            text-align: right
    .subscriptionlength
        padding: 1rem 0
    .plan-feature
        font-size: $font-size-s
        list-style-position: inside
        padding: 0 0 0 2rem 

.pricing-plan-card
    .content-wrapper
        align-items: flex-start
        justify-content: flex-start
        justify-items: flex-start
        .content
            width: 100%