@import 'mixins'

.container-header
    width: 100%
    display: flex
    position: fixed
    justify-content: space-between
    background: linear-gradient(109.75deg, #F4F4FC 2.14%, #E3E6F4 100%)
    z-index: 1000

    @media screen and (min-width: 990px)
        padding: 1rem 2rem
        align-items: center

    @include mobile
        padding: 2rem

    .container-header-section
        display: flex
        .component-button
            font-weight: 700
            &:nth-child(even)
                @include primaryColors
            &:nth-child(odd)
                @include secondaryColors    
                