.frame-1321313897-2
  align-items: flex-end
  border-radius: 22.71px
  display: flex
  height: 161px
  left: 268px
  overflow: hidden
  position: absolute
  top: 184px
  width: 161px

.overlap-group-78
  height: 725px
  margin-bottom: -532.8px
  margin-left: -59px
  position: relative
  width: 1219px

.group-1359843-2
  height: 725px
  left: 28px
  position: absolute
  top: 0
  width: 825px

.rectangle-6
  height: 648px
  left: 59px
  mix-blend-mode: soft-light
  object-fit: cover
  position: absolute
  top: 32px
  width: 1160px

.ellipse-88-3
  height: 195px
  left: 0
  position: absolute
  top: 314px
  width: 195px

.group-1359787-3
  height: 91px
  left: 93px
  position: absolute
  top: 66px
  width: 91px

.frame-1321313897-2.frame-1321313897-3
  left: 235px
  top: 283px

