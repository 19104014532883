.bilock-fill-1
  align-items: flex-end
  display: flex
  margin-left: -1.27px
  margin-top: 1px
  overflow: hidden
  padding: 0 0.6px
  width: 4px

.group-52
  height: 4px
  margin-bottom: 0
  width: 3px

.bilock-fill-1.bilock-fill-3
  justify-content: flex-end
  margin-left: unset
  margin-top: unset
  min-width: 3px
  overflow: unset
  padding: 0 0.5px
  width: unset

.bilock-fill-1.bilock-fill-3 .group-52
  height: 3px
  width: 2px

