.frame-1321313914-4,
.frame-1321313914-5,
.frame-1321313914-6,
.frame-1321313914-7
  align-items: center
  display: flex
  height: 32px
  justify-content: flex-end
  margin-top: 16px
  min-width: 81px

.group-23,
.group-24,
.group-25,
.group-26
  height: 22px
  margin-bottom: 0
  width: 20px

.voice,
.voice-1,
.voice-2,
.voice-3
  letter-spacing: 0
  line-height: 32px
  margin-left: 11px
  min-height: 32px
  min-width: 49px
  white-space: nowrap

