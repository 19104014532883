.frame-1321313908-2
  align-items: flex-start
  display: flex
  flex-direction: column
  min-height: 108px
  width: 481px

.for
  letter-spacing: 0
  min-height: 29px

.visitors-simply-scan
  letter-spacing: 0
  line-height: 32px
  margin-top: 20px
  min-height: 59px
  width: 481px

.frame-1321313908-2.frame-1321313908-3
  min-height: 173px

.frame-1321313908-2.frame-1321313908-3 .visitors-simply-scan
  min-height: 124px

