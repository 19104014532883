.frame-1321313978
  align-items: flex-start
  display: flex

.screenshot-2022-02-25-at-1646-1
  height: 46px
  mix-blend-mode: darken
  object-fit: cover
  width: 46px

.scan-the-code-to-see
  letter-spacing: 0
  line-height: 24px
  margin-left: 16px
  min-height: 46px

.frame-1321313978.frame-1321313977-1
  margin-top: 80px

.frame-1321313978.frame-1321313977-1 .screenshot-2022-02-25-at-1646-1
  mix-blend-mode: unset

.frame-1321313978.frame-1321313978-1
  margin-top: 144px

