.frame-1321313936-1
  align-items: center
  align-self: center
  display: flex
  height: 44px
  min-width: 1360px
  position: relative

.frame-1321314061-3
  height: 17px
  width: 192px

.frame-1321313936-1.frame-1359970-1,
.frame-1321313936-1.frame-1321313936-2,
.frame-1321313936-1.frame-1321313936-3,
.frame-1321313936-1.frame-1321313936-4,
.frame-1321313936-1.frame-1321313936-5
  align-self: unset

