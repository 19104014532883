@import 'rem'
@import 'variables'
@import 'mixins'

.component-contact-section
  align-items: flex-end
  // display: flex
  margin-top: 1px
  justify-content: flex-start
  font-size: $font-size-m
  color: $regent-gray
  a
    color: $gray

  @include desktop
    display: grid
    grid-template: repeat(2, 1fr) / repeat(2, 1fr)
    row-gap: 1rem
    grid-auto-flow: column
    font-size: $font-size-xs
    line-height: $font-size-m
    .right-section, .left-section
      width: 100%
      display: flex
      flex-direction: column
    .right-section
      grid-row-start: 1
      grid-row-end: 3
      align-items: flex-end
      text-align: right
    .patents-and-policy
      display: flex
      a
        color: $regent-gray
      div
        padding: 0 4rem 0 0

  @include mobile
    height: auto
    font-size: $font-size-xs
    .right-section, .left-section
      line-height: $font-size-s
      .patents
        padding: 1rem 0 
    .policy
      display: none

.component-contact-section-qrcode
  width: rem(240px)
  height: rem(80px)
  border-radius: 1rem
  padding: 0.25rem
  .qrcode, svg
    height: 4rem
    width: 4rem

.component-contact-section-qrcode-title
  text-transform: none !important
  a
    color: #536ae2

.buttonblock
  display: flex
  flex-direction: column
  align-items: center
  justify-self: flex-start
  span, a 
    padding: 0.2rem 0 0 0
    font-size: $font-size-xs

.patents-and-policy
  color: $gray
  font-size: $font-size-xs
  @include mobile
    div
      padding: 0.5rem
      line-height: $font-size-m
  @include desktop
    display: grid
    grid-template-columns: repeat(3, 1fr)
    justify-self: flex-start
