@import 'variables'
@import 'mixins'
@import 'rem'

.plan-option
  height: rem(408px)
  .content > .title
    font-size: rem(24px)
    word-break: break-word
    white-space: pre-line
  .content > .option
    width: 100%
    display: flex
    height: $font-size-m
    line-height: $font-size-m
    text-transform: capitalize
    .image-component
      height: inherit
      width: $font-size-m
      margin: 0 1rem 0 0
  @include desktop
    width: rem(231px)
  @include mobile
    width: rem(327px)


.service-screen
  justify-content: space-evenly
  height: 100%
  padding-top: 0
  height: 60rem

.purchase-links
  display: flex
  flex-direction: column
  height: 100%
  width: 100%
  justify-content: flex-end
