@import 'variables'

.align-top
  align-items: flex-start !important

.x2-page2
  align-items: flex-end
  background-size: 100% 100%
  display: flex
  flex-direction: column
  height: 900px
  overflow: hidden
  padding: 24px 0
  position: relative

.overlap-group1-6
  height: 583px
  margin-right: -5.0px
  margin-top: 118px
  position: relative
  width: 1305px

.frame-1321313930
  align-items: center
  background-color: $white
  border-radius: 40px
  display: flex
  flex-direction: column
  left: 0
  min-height: 583px
  padding: 63.5px 0
  position: absolute
  top: 0
  width: 568px

.frame-container-3
  height: 454px
  left: 446px
  position: absolute
  top: 85px
  width: 859px

.frame-1359979-2
  align-items: flex-start
  display: flex
  height: 107px
  left: 530px
  min-width: 186px
  position: absolute
  top: 238px
  transform: rotate(-180deg)

.qr
  height: 1px
  margin-left: -3px
  margin-top: 0
  width: 1px

.frame-1359979-3
  background-size: 100% 100%
  height: 454px
  left: 0
  position: absolute
  top: 0
  transform: rotate(-180deg)
  width: 859px

.frame-1359979-4
  background-size: 100% 100%
  height: 424px
  left: 238px
  position: absolute
  top: 8px
  transform: rotate(-180deg)
  width: 421px

.frame-1359979-5
  background-size: 100% 100%
  height: 392px
  left: 249px
  position: absolute
  top: 15px
  transform: rotate(-180deg)
  width: 396px

