@import 'variables'

.phone-image
  @media screen and (min-width: 990px)
    background: url('/static/img/thirdparty.png')
    background-repeat: no-repeat
    background-position-y: 5rem
    width: 40rem
    height: 50rem


.x2-page5
  align-items: center
  background-size: 100% 100%
  display: flex
  flex-direction: column
  padding: 24px 0
  position: relative

.frame-1321314080
  align-items: flex-start
  display: flex
  margin-top: 118px
  min-width: 1160px

.frame-1359971
  align-items: center
  display: flex
  height: 583px
  justify-content: flex-end
  min-width: 568px

.frame-1359971-1
  align-items: center
  display: flex
  flex-direction: column
  margin-bottom: 35.79px
  min-height: 479px
  width: 116px

.image-91
  height: 30px
  margin-left: 2.0px
  mix-blend-mode: darken
  width: 114px

.google_-maps-logo-1
  align-self: flex-start
  height: 18px
  margin-left: 5px
  margin-top: 33px
  width: 91px

.image-92
  align-self: flex-start
  height: 28px
  margin-left: 6px
  margin-top: 33px
  mix-blend-mode: darken
  width: 84px

.image-93
  height: 24px
  margin-right: 17.0px
  margin-top: 33px
  width: 81px

.google-forms_logo-e1529921391153-1
  height: 29px
  margin-right: 9.0px
  margin-top: 33px
  object-fit: cover
  width: 107px

.group-1359872
  align-items: flex-start
  align-self: flex-start
  display: flex
  margin-left: 5px
  margin-top: 33px
  min-width: 93px

.adobe_-acrobat_dc_logo_2020-1
  height: 19px
  margin-top: 0
  width: 20px

.adobe-pdf
  letter-spacing: 0
  line-height: 22.2px
  margin-left: 8px
  min-height: 19px
  white-space: nowrap

.service-now_logo-1
  height: 14px
  margin-right: 3.0px
  margin-top: 33px
  object-fit: cover
  width: 97px

.x1_-qa-xi-zt-hci-ka5-b-zu-mkc-y2-q-1
  height: 28px
  margin-left: 2.32px
  margin-top: 33px
  object-fit: cover
  width: 102px

.x6117a82537bc3b7a02bd
  height: 25px
  margin-left: 2.17px
  margin-top: 33px
  width: 102px

.overlap-group1-9
  align-self: flex-end
  height: 710px
  margin-bottom: -137.15px
  margin-left: 85px
  position: relative
  width: 419px

.shadows-2
  height: 710px
  left: 5px
  position: absolute
  top: 0
  width: 414px

.overlap-group-12
  height: 594px
  left: 0
  position: absolute
  top: 9px
  width: 327px

.frame-3
  height: 539px
  left: 67px
  position: absolute
  top: 55px
  width: 260px

.put-screen-in-here-3
  align-items: flex-start
  background-color: $mirage
  border-radius: 29.5px
  display: flex
  height: 519px
  left: 12px
  overflow: hidden
  position: absolute
  top: 0
  width: 239px

.intercom-mobile-door-only-2
  height: 519px
  margin-top: -0.22px
  width: 240px

.home-indicator-white-3
  background-color: $white
  border-radius: 81.95px
  height: 3px
  left: 89px
  position: absolute
  top: 509px
  width: 86px

.screen-shine-1
  height: 519px
  left: 12px
  mix-blend-mode: screen
  position: absolute
  top: 0
  width: 239px

.frame-1321314079
  align-items: center
  background-color: $white
  border-radius: 40px
  display: flex
  flex-direction: column
  margin-left: 24px
  min-height: 583px
  padding: 41.5px 0
  position: relative
  width: 568px

.frame-1321313896-2
  align-items: flex-start
  display: flex
  flex-direction: column
  min-height: 310px
  width: 472px

.third-party-zero-problem
  letter-spacing: 0
  line-height: 52px
  min-height: 104px
  width: 472px

.designed-to-effortle
  letter-spacing: 0
  line-height: 32px
  margin-top: 20px
  min-height: 134px
  width: 472px

.list-of-integrated-partners
  letter-spacing: 0
  line-height: 32px
  margin-top: 20px
  min-height: 32px
  white-space: nowrap

