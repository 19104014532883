@import 'variables'

.x2-page3
  align-items: center
  background-size: 100% 100%
  display: flex
  flex-direction: column
  min-height: 900px
  padding: 24px 0
  position: relative

.frame-1321314073
  align-items: flex-end
  display: flex
  height: 583px
  justify-content: flex-end
  margin-top: 118px
  min-width: 1160px

.overlap-group1-8
  height: 710px
  margin-bottom: -169.36px
  position: relative
  width: 433px

.frame-1359979-6
  background-size: 100% 100%
  height: 710px
  left: 19px
  position: absolute
  top: 0
  width: 414px

.overlap-group-11
  height: 594px
  left: 0
  position: absolute
  top: 0
  width: 327px

.frame-2
  height: 539px
  left: 67px
  position: absolute
  top: 55px
  width: 260px

.put-screen-in-here-2
  align-items: flex-start
  background-color: $mirage
  border-radius: 29.5px
  display: flex
  height: 519px
  left: 12px
  min-width: 239px
  position: absolute
  top: 0

.intercom-mobile-door-only-1
  height: 519px
  margin-top: -1.71px
  width: 240px

.home-indicator-white-2
  background-color: $white
  border-radius: 81.95px
  height: 3px
  left: 89px
  position: absolute
  top: 509px
  width: 86px

.screen-shine
  height: 519px
  left: 12px
  mix-blend-mode: screen
  position: absolute
  top: 0
  width: 239px

.group-1359888
  align-items: center
  background-color: $white-2
  border-radius: 40px
  display: flex
  flex-direction: column
  margin-left: 95px
  min-height: 583px
  padding: 32px 0
  position: relative
  width: 568px

.frame-1321313977-2
  align-items: center
  display: flex
  height: 46px
  margin-right: 96.0px
  margin-top: 71px
  min-width: 376px

.screenshot-2022-02-25-at-1644-1
  height: 40px
  object-fit: cover
  width: 40px

.scan-the-code-to-see-3
  letter-spacing: 0
  line-height: 24px
  margin-left: 16px
  min-height: 46px
  width: 320px

