@import 'variables'

.x5-page7
  align-items: flex-start
  background-size: 100% 100%
  display: flex
  height: 900px
  overflow: hidden

.overlap-group1-38
  height: 1051px
  position: relative

.frame-1359972
  height: 900px
  left: 0
  position: absolute
  top: 0

.frame-1321314153
  align-items: center
  background-color: $white
  border-radius: 40px
  display: flex
  flex-direction: column
  left: 140px
  min-height: 583px
  padding: 94px 0
  position: absolute
  top: 186px
  width: 568px

.help-desk-avit-support-reimagined
  letter-spacing: 0
  line-height: 52px
  min-height: 99px
  width: 472px

.with-live-video-help-desk-support
  letter-spacing: 0
  margin-right: 39.0px
  margin-top: 20px
  min-height: 29px
  min-width: 433px

.no-matter-how-many-r
  letter-spacing: 0
  line-height: 32px
  margin-top: 20px
  min-height: 94px
  width: 472px

.overlap-group-89
  height: 846px
  left: 405px
  position: absolute
  top: 205px
  width: 869px

.qr-3
  height: 207px
  left: 726px
  position: absolute
  top: 178px
  width: 143px

.frame-1359971-6
  height: 846px
  left: 0
  position: absolute
  top: 0
  width: 831px

.frame-1359971-7
  align-items: center
  display: flex
  height: 44px
  left: 40px
  min-width: 1360px
  position: absolute
  top: 24px

.frame-1321314061-30
  height: 17px
  width: 192px

.frame-1359884-31
  align-items: flex-start
  display: flex
  height: 32px
  margin-left: 153px
  min-width: 574px

.intercoms-33
  letter-spacing: 0
  line-height: 32px
  min-height: 32px
  min-width: 72px
  white-space: nowrap

.video-concierge-42
  letter-spacing: 0
  line-height: 32px
  margin-left: 62px
  min-height: 32px
  min-width: 116px
  white-space: nowrap

.help-as-a-service-43
  letter-spacing: 0
  line-height: 32px
  margin-left: 62px
  min-height: 32px
  min-width: 122px
  white-space: nowrap

.contact-us-31
  letter-spacing: 0
  line-height: 32px
  margin-left: 62px
  min-height: 32px
  white-space: nowrap

