@import 'variables'

.frame-1321314062-17
  align-items: flex-start
  align-self: flex-start
  display: flex
  margin-left: 153px
  margin-top: -3px
  min-width: 288px
  position: relative

.button-51
  align-items: center
  background-color: $blueberry
  border-radius: 10px
  box-shadow: 0px 10px 25px #6980fe80
  display: flex
  height: 50px
  margin-left: 8px
  overflow: hidden
  padding: 0 9px
  width: 140px

.feedbacks-53
  height: 16px
  letter-spacing: 0
  line-height: 16px
  min-width: 122px
  text-align: center
  white-space: nowrap

.frame-1321314062-17.frame-1321314062-24 .button-51,
.frame-1321314062-17.frame-1321314062-31 .button-51
  padding: 0 8.5px

.frame-1321314062-17.frame-1321314062-24 .feedbacks-53,
.frame-1321314062-17.frame-1321314062-31 .feedbacks-53
  min-width: 123px

