@import 'mixins'

.partner-block
    .card
        width: 12rem
        height: 10rem
        .content
            .image-component
                width: inherit
                height: inherit
                background-size: auto 8rem
    @include desktop
        .childnodes
            grid-template-columns: repeat(6, 1fr)
            width: 100%
    @include mobile
        .childnodes
            grid-template-columns: repeat(2, 1fr)