.frame-1321313936-8
  align-items: center
  display: flex
  height: 44px
  min-width: 1360px
  position: relative

.frame-1321314061-17
  height: 17px
  width: 192px

.frame-1359884-18
  align-items: flex-start
  display: flex
  height: 32px
  margin-left: 153px
  min-width: 574px

.intercoms-20
  letter-spacing: 0
  line-height: 32px
  min-height: 32px
  min-width: 72px
  white-space: nowrap

.video-concierge-29
  letter-spacing: 0
  line-height: 32px
  margin-left: 62px
  min-height: 32px
  min-width: 116px
  white-space: nowrap

.help-as-a-service-29
  letter-spacing: 0
  line-height: 32px
  margin-left: 62px
  min-height: 32px
  min-width: 122px
  white-space: nowrap

.contact-us-18
  letter-spacing: 0
  line-height: 32px
  margin-left: 62px
  min-height: 32px
  white-space: nowrap

