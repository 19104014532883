@import 'variables'
@import 'rem'

.staff-screen
    color: $white !important
    .fullscreen-content > .subtitle
        color: $white 

    .card
        &:nth-child(1)
            .staff
                justify-content: flex-end
            

.blurred
    .content
        backdrop-filter: blur(8px)

.hands-overlay
    z-index: 999
    pointer-events: none
    background-size: auto rem(578px)
    background-position-y: bottom

.staff
    display: flex
    height: rem(340px)
    width: 100%
    color: $white
    .employee
        display: flex
        flex-direction: column
        align-content: center
        padding: 0 4rem

        &.direct:nth-child(2),
        &.reverse:nth-child(1)
            align-self: flex-end
            
        & > *
            text-align: center
        div
            padding: 0.5rem 0
        .photo
            width: rem(128px)
            height: rem(128px)
            border-radius: rem(64px)
            background-size: rem(128px)
            border: rem(8px) solid $white
            background-position-y: center
            background-color: $white-3
            &.blank
                border: unset
                display: flex
                align-content: center
                justify-content: center
                flex-direction: column
                span
                    display: block
                    padding: 1rem
                    white-space: pre-line
                    word-break: break-word
        .qr
            width: rem(78px)
            height: rem(78px)
            margin: 0.5rem auto 0 auto
