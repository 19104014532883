.group-1359838
  align-items: center
  display: flex
  flex-direction: column
  margin-top: 42px
  min-height: 114px
  width: 570px

.the-way-we-communica-2
  letter-spacing: 0
  line-height: 32px
  margin-right: 4.0px
  min-height: 58px
  text-align: center
  width: 566px

.lets-change-that-2
  letter-spacing: 0
  line-height: 32px
  margin-right: 4.0px
  margin-top: 24px
  min-height: 32px
  min-width: 166px
  text-align: center
  white-space: nowrap

.group-1359838.group-1359838-1,
.group-1359838.group-1359838-2
  margin-left: 4.0px

