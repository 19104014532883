.rectangle-button-6
  align-items: flex-start
  background-size: 100% 100%
  display: flex
  height: 12px
  margin-left: 0
  margin-top: 4px
  min-width: 12px
  padding: 3.6px 2.4px
  transform: rotate(-90.00deg)

.number
  height: 4px
  letter-spacing: 0
  transform: rotate(-0.57deg)
  width: 7px

.rectangle-button-6.rectangle-button-7
  height: 13px
  margin-top: 5px
  min-width: 13px
  padding: 3.9px 2.6px

.rectangle-button-6.rectangle-button-7 .number
  height: 5px

