@import 'rem'
@import 'variables'

.modal-overlay
    background: linear-gradient(109.75deg, #F4F4FC 2.14%, #E3E6F4 100%)
    color: $black
    display: flex

.burger-button
    height: 1.5rem
    filter: invert(100%)

.burger-menu
    display: flex
    flex-direction: column
    justify-content: flex-start
    padding: 2rem
    a 
        color: $black !important

.modal-close-button
    display: block
    position: absolute
    z-index: 99999
    top: 2rem
    right: 2rem
    width: 1rem
    height: 1rem