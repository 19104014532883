.column-layout
    @media screen and (min-width: 990px)
        display: grid
        grid-template-columns: repeat(var(--columns), 1fr)
        grid-template-rows: repeat(1, 1fr)
        align-items: end
    
    @media screen and (max-width: 989px)
      padding: 0 0 1rem 0
      align-items: center
      flex-direction: column
      &.rl
        flex-direction: column-reverse