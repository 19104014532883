@import 'variables'
@import 'mixins'
@import 'rem'

.browserhint
    display: flex !important
    line-height: $font-size-xl !important
    @include mobile
        flex-direction: column
        align-items: center
    svg
        display: block
        width: 4rem
        height: 4rem

.modal-overlay
   background: $white-2 !important
   backdrop-filter: blur(4px)
   .multicard
        display: grid
        grid-columns: 1fr
        column-gap: 1rem

        .childnodes
            justify-items: center
        :nth-child(2)
            align-self: start
        .card
            height: rem(271px)
            width: rem(271px)
            @include mobile 
                height: 9rem
                width: 9rem
            .title
                display: flex
                justify-content: center
                line-height: 4rem
                padding: 0
                img
                    height: 12rem !important
                    @include mobile 
                        height: 5rem !important
                    width: auto !important
                    display: flex
                    margin: 0 auto
                    border-radius: rem(20px)
                    
            .subtitle
                line-height: $font-size-xl
                text-align: center
                font-weight: 700
                justify-content: center

        .content
            background: $white
            @include withShadow($shadowColor: $gray)
        .childnodes
            @include mobile
                grid-template-rows: unset