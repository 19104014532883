@import 'variables'
@import 'rem'
@import 'mixins'

.qrvideo .youtube-link
        padding: 2rem 0 0 0

.qrvideo .text
        margin: auto 0 0 0 

.qrideo-splash
    .splash-backdrop
        @include mobile
            width: 100%
            background-position-y: bottom
            background-size: rem(480px)


.feature-carousel-screen
    min-height: 120rem
    @include mobile
        padding-left: 0
        padding-right: 0
        .content > .title, .content > .subtitle
            padding-left: 1rem
            padding-right: 1rem

.logo-card
    display: flex
    height: 100%
    .thirdparty-illustration
        height: 100%
        width: 100%
        background-size: cover
        background-clip: padding-box
        background-size: auto rem(560px)
        background-position-y: rem(-30px)
    .thirdparty-logos
        height: 100%
        @include desktop
            display: flex
            flex-direction: column
            justify-content: space-between
        @include mobile
            display: grid
            grid-template-columns: repeat(2, 1fr)
            width: 100%
            &.odd-count
                *:first-child
                    width: 100%
                    grid-column-start: 1
                    grid-column-end: 3
            :nth-child(odd)
                justify-self: flex-end
            :nth-child(even)
                justify-self: flex-start
        div
            width: rem(84px)
            max-height: 2rem
        
    
.mobile-hand-background
    @include desktop
        visibility: hidden
    @include mobile
        width: 100%
        height: 30rem
        .background
            position: absolute
            width: 100vw
            background-position-x: 0
            left: -1rem
            bottom: 8rem

.illustration_mobiles
    @include desktop
        display: flex
        margin: auto 0
        width: 100%
        justify-content: space-evenly
        div 
            display: flex
            height: 10rem
            width: 6rem
            &:not(:first-child):not(:last-child)
                justify-content: space-between
                width: 16rem
                &::after,  &::before
                    display: block
                    content: ''
                    width: 4rem
                    height: 100%
                    background: url('/static/img/arrow.png')
                    background-repeat: no-repeat
                    background-position: center
    @include mobile
        display: block
        position: relative
        width: 100%
        height: 100%
        div
            &:nth-child(1)
            &:nth-child(2)
                top: 30%
                left: 30%
            &:nth-child(3)
                top: 50%
                left: 50%

.feature-carousel-block
    @include desktop
        height: 64rem
    @include mobile
        width: 100%
        .content
            padding: 0 0