.frame-1321313936-6
  align-items: center
  display: flex
  height: 44px
  min-width: 1360px
  position: relative

.frame-1321314061-9
  height: 17px
  width: 192px

