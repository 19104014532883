@import 'variables'
@import 'mixins'
@import 'rem'


@mixin cardScale($order, $stackLength)
    $baseWidth: rem(170px)
    $baseHeight: rem(380px)
    width: calc($baseWidth * 0.5 + $baseWidth / $stackLength * ( $order + 1 ) * 0.5)
    height: calc($baseHeight * 0.5 + $baseHeight / $stackLength * ( $order + 1 ) * 0.5)
    // z-index: calc(100 + $order)
    cursor: pointer
    &:hover, &:focus
        z-index: calc(100 + $stackLength)
        height: calc($baseHeight + $baseHeight / $stackLength)
        width: calc($baseWidth + $baseWidth / $stackLength)
    // &:hover, &:focus
    //     z-index: calc(100 + $stackLength)
    //     width: calc(6rem + $stackLength * 0.5rem)
    //     height: calc(14rem + $stackLength * 0.2rem)
    
.feature-carousel
    @include mobile
        overflow-x: scroll
        width: 100vw
        padding: 0 3rem
    display: grid
    grid-template-columns: repeat(var(--stack-length), 1fr)
    height: 45rem
    padding: 2rem 0 2rem 0

    .item
        &:nth-child(even)
            .title
                padding: 10rem 1rem 0 1rem
                .spacer
                    height: calc(var(--stack-length) * 0.5rem - var(--item-order) * 0.5rem)
            
    .item
        height: 100%
        display: flex
        flex-direction: column
        justify-content: space-between
        margin-left: -2rem
        .title
            padding: 1rem
            display: flex
            flex-direction: column
            justify-content: flex-start
            position: relative
            span
                font-size: $font-size-xs
                line-height: $font-size-s
                color: $gray
                text-align: center
                display: block
            .spacer
                height: calc(10rem + var(--stack-length) * 0.5rem - var(--item-order) * 0.5rem)
                width: 1px
                margin: 2rem auto
                background-color: $gray
                position: absolute
                top: 100%
                right: 50%
        .picwrapper
            display: flex
            flex-direction: column
            justify-content: center
            border-radius: rem(8px)
            background-size: cover
            @include cardScale($order: var(--item-order), $stackLength: var(--stack-length))
            