@import 'variables'
@import 'mixins'

.container-content.home
    background-image: url('/static/img/backdrop.png')
    background-size: 100%
    background-repeat: no-repeat  
    @include desktop
      .content-screen
        &.homesplash
          justify-content: center



.homepage-splash
  .title
    @include pageTitle

.home-page
  align-items: center
  background-position: 50% 50%
  background-size: cover
  display: flex
  flex-direction: column
  overflow: hidden
  position: relative

.frame-1321314066
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-right: 1.0px
  margin-top: 123px
  min-height: 1950px
  width: 1161px

.frame-1321314060
  align-items: center
  display: flex
  min-width: 1161px

.frame-1321313925-1
  align-items: flex-start
  display: flex
  flex-direction: column
  min-height: 435px
  width: 561px

.frame-1321313924
  align-items: flex-start
  display: flex
  flex-direction: column
  min-height: 347px
  width: 561px

.connection-reimagined-1
  letter-spacing: 0
  min-height: 176px

.teleportivity-create-1
  letter-spacing: 0
  line-height: 32px
  margin-top: 20px
  min-height: 91px
  width: 561px

.frame-1321313977
  align-items: center
  display: flex
  margin-top: 20px
  min-width: 307px

.qr-code-landing
  height: 40px
  width: 40px

.group-1359922
  align-items: flex-start
  display: flex
  height: 24px
  margin-left: 16px
  min-width: 255px

.scan-to-talk-to-us-live-or-click
  letter-spacing: 0
  line-height: 24px
  min-height: 24px
  min-width: 213px
  white-space: nowrap

.here
  letter-spacing: 0
  line-height: 24px
  margin-left: 5px
  min-height: 24px
  white-space: nowrap

.discover-teleportivity-1
  letter-spacing: 0
  line-height: 32px
  margin-top: 56px
  min-height: 32px
  white-space: nowrap

.frame-1321313930-1
  height: 560px
  margin-left: 32px
  object-fit: cover
  width: 568px

.frame-1321313904
  align-items: flex-start
  display: flex
  margin-top: 150px
  min-width: 1161px

.frame-1321313900-1
  align-items: flex-end
  background-color: $white-2
  border-radius: 40px
  display: flex
  height: 480px
  min-width: 371px
  padding: 0 32px

.frame-container-2
  height: 552px
  margin-bottom: -119.95px
  position: relative
  width: 307px

.frame-1321313927-2
  align-items: flex-start
  display: flex
  flex-direction: column
  left: 0
  min-height: 257px
  position: absolute
  top: 0
  width: 307px

.frame-1321313898-2
  align-items: flex-start
  display: flex
  flex-direction: column
  min-height: 193px
  width: 307px

.intercoms-1
  letter-spacing: 0
  line-height: 52px
  min-height: 52px
  white-space: nowrap

.redefine-what-your-i-1
  letter-spacing: 0
  line-height: 32px
  margin-top: 20px
  min-height: 121px
  width: 307px

.more-2
  letter-spacing: 0
  line-height: 32px
  margin-top: 32px
  min-height: 32px
  white-space: nowrap

.frame-1359978
  background-size: 100% 100%
  height: 308px
  left: 54px
  position: absolute
  top: 244px
  width: 213px

.frame-1321313901
  align-items: center
  background-color: $white-2
  border-radius: 40px
  display: flex
  flex-direction: column
  margin-left: 24px
  min-height: 480px
  position: relative
  width: 371px

.frame-1321313928
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-top: 48px
  min-height: 311px
  position: relative
  width: 307px

.frame-1321313902
  align-items: center
  background-color: $white-2
  border-radius: 40px
  display: flex
  flex-direction: column
  margin-left: 24px
  min-height: 480px
  width: 371px

.frame-1321313929
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-top: 48px
  min-height: 309px
  position: relative
  width: 307px

.frame-1359978-1
  height: 235px
  margin-right: 1.0px
  margin-top: 17px
  width: 372px

.frame-1321314065
  align-items: center
  background-color: $white
  border-radius: 40px
  display: flex
  height: 287px
  margin-top: 150px
  min-width: 1161px
  padding: 0 37px

.frame-1321314034-1
  align-items: flex-start
  display: flex
  flex-direction: column
  min-height: 195px
  width: 451px

.reimagine-your-connection-today-1
  letter-spacing: 0
  line-height: 52px
  min-height: 104px
  width: 339px

.get-started-with-us-1
  letter-spacing: 0
  line-height: 32px
  margin-top: 16px
  min-height: 75px
  width: 451px

.frame-1359925
  align-items: center
  align-self: flex-end
  display: flex
  margin-bottom: 46px
  margin-left: 137px
  min-width: 499px

.find-authorised-dealer
  height: 32px
  letter-spacing: 0
  line-height: 32px
  min-width: 215px
  white-space: nowrap

.button-2
  align-items: center
  background-color: $blueberry
  border-radius: 10px
  box-shadow: 0px 10px 20px #828c9e66
  display: flex
  height: 50px
  margin-left: 48px
  overflow: hidden
  padding: 0 21px
  width: 236px

.feedbacks-2
  height: 16px
  letter-spacing: 0
  line-height: 16px
  min-width: 194px
  text-align: center
  white-space: nowrap

.frame-1321313952-1
  align-items: flex-start
  display: flex
  margin-top: 150px
  min-width: 1156px

.frame-1321313946
  height: 172px
  width: 172px

.frame-1321313947
  height: 172px
  margin-left: 24px
  width: 174px

.frame-13213139-2
  height: 173px
  margin-left: 24px
  width: 172px

.frame-1321313949-1
  height: 172px
  margin-left: 24px
  width: 172px

.frame-1321313950
  height: 173px
  margin-left: 24px
  width: 174px

