@import './variables'

.card-title
  font-size: $font-size-xl

.montserrat-normal-regent-gray-18px
  color: $regent-gray
  font-family: $font-family-montserrat
  font-size: $font-size-m
  font-style: normal
  font-weight: 400

.montserrat-normal-french-gray-14px
  color: $french-gray
  font-family: $font-family-montserrat
  font-size: $font-size-xs
  font-style: normal
  font-weight: 400

.montserrat-normal-fiord-14px
  color: $fiord
  font-family: $font-family-montserrat
  font-size: $font-size-xs
  font-style: normal
  font-weight: 400

.montserrat-bold-steel-gray-28px
  color: $steel-gray
  font-family: $font-family-montserrat
  font-size: $font-size-l
  font-style: normal
  font-weight: 700

.montserrat-medium-blueberry-16px
  color: $blueberry
  font-family: $font-family-montserrat
  font-size: $font-size-s
  font-style: normal
  font-weight: 500

.montserrat-medium-white-16px
  color: $white
  font-family: $font-family-montserrat
  font-size: $font-size-s
  font-style: normal
  font-weight: 500

.montserrat-bold-steel-gray-36px
  color: $steel-gray
  font-family: $font-family-montserrat
  font-size: $font-size-xl
  font-style: normal
  font-weight: 700

.montserrat-semi-bold-blueberry-18px
  color: $blueberry
  font-family: $font-family-montserrat
  font-size: $font-size-m
  font-style: normal
  font-weight: 600

.montserrat-normal-regent-gray-14px
  color: $regent-gray
  font-family: $font-family-montserrat
  font-size: $font-size-xs
  font-style: normal
  font-weight: 400

.montserrat-normal-regent-gray-16px
  color: $regent-gray
  font-family: $font-family-montserrat
  font-size: $font-size-s
  font-style: normal
  font-weight: 400

.montserrat-semi-bold-regent-gray-18px
  color: $regent-gray
  font-family: $font-family-montserrat
  font-size: $font-size-m
  font-style: normal
  font-weight: 600

.montserrat-normal-steel-gray-16px
  color: $steel-gray
  font-family: $font-family-montserrat
  font-size: $font-size-s
  font-style: normal
  font-weight: 400

.roboto-medium-steel-gray-2px
  color: $steel-gray-2
  font-family: $font-family-roboto
  font-size: $font-size-xxxs
  font-style: normal
  font-weight: 500

.montserrat-semi-bold-steel-gray-18px
  color: $steel-gray
  font-family: $font-family-montserrat
  font-size: $font-size-m
  font-style: normal
  font-weight: 600

.montserrat-semi-bold-steel-gray-16px
  color: $steel-gray
  font-family: $font-family-montserrat
  font-size: $font-size-s
  font-style: normal
  font-weight: 600

.montserrat-normal-fiord-18px
  color: $fiord
  font-family: $font-family-montserrat
  font-size: $font-size-m
  font-style: normal
  font-weight: 400

.montserrat-semi-bold-royal-blue-18px
  color: $royal-blue
  font-family: $font-family-montserrat
  font-size: $font-size-m
  font-style: normal
  font-weight: 600

.montserrat-semi-bold-black-24px
  color: $black
  font-family: $font-family-montserrat
  font-size: 24px
  font-style: normal
  font-weight: 600

.montserrat-semi-bold-steel-gray-24px
  color: $steel-gray
  font-family: $font-family-montserrat
  font-size: 24px
  font-style: normal
  font-weight: 600

.roboto-medium-steel-gray-1-7px
  color: $steel-gray-2
  font-family: $font-family-roboto
  font-size: 1.7px
  font-style: normal
  font-weight: 500

.montserrat-medium-regent-gray-11-3px
  color: $regent-gray
  font-family: $font-family-montserrat
  font-size: 11.3px
  font-style: normal
  font-weight: 500

.montserrat-normal-steel-gray-18px
  color: $steel-gray
  font-family: $font-family-montserrat
  font-size: $font-size-m
  font-style: normal
  font-weight: 400

.montserrat-semi-bold-white-18px
  color: $white
  font-family: $font-family-montserrat
  font-size: $font-size-m
  font-style: normal
  font-weight: 600

.roboto-normal-shark-2px
  color: $shark
  font-family: $font-family-roboto
  font-size: $font-size-xxxs
  font-style: normal
  font-weight: 400

.roboto-medium-steel-gray-2px-2
  color: $steel-gray
  font-family: $font-family-roboto
  font-size: $font-size-xxxs
  font-style: normal
  font-weight: 500

.roboto-normal-white-1-6px
  color: $white
  font-family: $font-family-roboto
  font-size: 1.6px
  font-style: normal
  font-weight: 400

.montserrat-medium-white-12-9px
  color: $white
  font-family: $font-family-montserrat
  font-size: 12.9px
  font-style: normal
  font-weight: 500

.montserrat-bold-steel-gray-40px
  color: $steel-gray
  font-family: $font-family-montserrat
  font-size: 40px
  font-style: normal
  font-weight: 700

.montserrat-bold-steel-gray-72px
  color: $steel-gray
  font-family: $font-family-montserrat
  font-size: $font-size-xxl
  @media screen and (max-width: 989px)
    font-size: $font-size-xl
  font-style: normal
  font-weight: 700

.roboto-normal-white-1-7px,
.roboto-normal-white-1-7px-2
  color: $white
  font-family: $font-family-roboto
  font-size: 1.7px
  font-style: normal
  font-weight: 400

.montserrat-normal-white-18px
  color: $white
  font-family: $font-family-montserrat
  font-size: $font-size-m
  font-style: normal
  font-weight: 400

.montserrat-semi-bold-royal-blue-16px
  color: $royal-blue
  font-family: $font-family-montserrat
  font-size: $font-size-s
  font-style: normal
  font-weight: 600

.roboto-medium-apple-1-7px
  color: $apple
  font-family: $font-family-roboto
  font-size: 1.7px
  font-style: normal
  font-weight: 500

.roboto-medium-cadet-blue-1-7px
  color: $cadet-blue-2
  font-family: $font-family-roboto
  font-size: 1.7px
  font-style: normal
  font-weight: 500

.roboto-medium-shark-2px
  color: $shark
  font-family: $font-family-roboto
  font-size: $font-size-xxxs
  font-style: normal
  font-weight: 500

.roboto-medium-white-1-4px
  color: $white
  font-family: $font-family-roboto
  font-size: 1.4px
  font-style: normal
  font-weight: 500

.roboto-normal-cadet-blue-2px,
.roboto-normal-cadet-blue-2px-2
  color: $cadet-blue-2
  font-family: $font-family-roboto
  font-size: $font-size-xxxs
  font-style: normal
  font-weight: 400

.roboto-normal-shark-1-7px
  color: $shark
  font-family: $font-family-roboto
  font-size: 1.7px
  font-style: normal
  font-weight: 400

.roboto-medium-steel-gray-1-7px-2
  color: $steel-gray
  font-family: $font-family-roboto
  font-size: 1.7px
  font-style: normal
  font-weight: 500

.roboto-normal-white-1-4px
  color: $white
  font-family: $font-family-roboto
  font-size: 1.4px
  font-style: normal
  font-weight: 400

.roboto-normal-shark-5-1px
  color: $shark
  font-family: $font-family-roboto
  font-size: 5.1px
  font-style: normal
  font-weight: 400

.montserrat-medium-royal-blue-14px
  color: $royal-blue
  font-family: $font-family-montserrat
  font-size: $font-size-xs
  font-style: normal
  font-weight: 500

.montserrat-bold-white-72px
  color: $white
  font-family: $font-family-montserrat
  font-size: 72px
  font-style: normal
  font-weight: 700

.montserrat-normal-white-18px-2
  color: $white-3
  font-family: $font-family-montserrat
  font-size: $font-size-m
  font-style: normal
  font-weight: 400

.roboto-normal-white-1-5px
  color: $white
  font-family: $font-family-roboto
  font-size: 1.5px
  font-style: normal
  font-weight: 400

.montserrat-normal-fiord-8-9px
  color: $fiord
  font-family: $font-family-montserrat
  font-size: 8.9px
  font-style: normal
  font-weight: 400

.montserrat-medium-regent-gray-5-7px
  color: $regent-gray
  font-family: $font-family-montserrat
  font-size: 5.7px
  font-style: normal
  font-weight: 500

.montserrat-bold-white-28px
  color: $white
  font-family: $font-family-montserrat
  font-size: $font-size-l
  font-style: normal
  font-weight: 700

.montserrat-semi-bold-white-16px
  color: $white
  font-family: $font-family-montserrat
  font-size: $font-size-s
  font-style: normal
  font-weight: 600

.roboto-normal-french-gray-3-7px
  color: $french-gray
  font-family: $font-family-roboto
  font-size: 3.7px
  font-style: normal
  font-weight: 400

.roboto-normal-french-gray-3px
  color: $french-gray
  font-family: $font-family-roboto
  font-size: 3px
  font-style: normal
  font-weight: 400

.montserrat-normal-white-14px
  color: $white
  font-family: $font-family-montserrat
  font-size: $font-size-xs
  font-style: normal
  font-weight: 400

.roboto-normal-french-gray-6-7px
  color: $french-gray
  font-family: $font-family-roboto
  font-size: 6.7px
  font-style: normal
  font-weight: 400

.sfprotext-semi-bold-white-12-3px
  color: $white
  font-family: $font-family-sf_pro_text-semibold
  font-size: 12.3px
  font-style: normal
  font-weight: 600

.sfprodisplay-thin-white-27-3px
  color: $white
  font-family: $font-family-sf_pro_display-thin
  font-size: 27.3px
  font-style: normal
  font-weight: 100

.sfprotext-regular-normal-star-dust-4-2px
  color: $star-dust
  font-family: $font-family-sf_pro_text-regular
  font-size: 4.2px
  font-style: normal
  font-weight: 400

.roboto-bold-manatee-2px
  color: $manatee
  font-family: $font-family-roboto
  font-size: $font-size-xxxs
  font-style: normal
  font-weight: 700

.roboto-medium-shark-3-7px
  color: $shark
  font-family: $font-family-roboto
  font-size: 3.7px
  font-style: normal
  font-weight: 500

.roboto-medium-buttercup-1-7px
  color: $buttercup
  font-family: $font-family-roboto
  font-size: 1.7px
  font-style: normal
  font-weight: 500

.roboto-medium-shark-2-8px
  color: $shark
  font-family: $font-family-roboto
  font-size: 2.8px
  font-style: normal
  font-weight: 500

.roboto-normal-white-2px
  color: $white
  font-family: $font-family-roboto
  font-size: $font-size-xxxs
  font-style: normal
  font-weight: 400

.roboto-normal-french-gray-4px
  color: $french-gray
  font-family: $font-family-roboto
  font-size: 4px
  font-style: normal
  font-weight: 400

.sfprodisplay-thin-white-16-6px
  color: $white
  font-family: $font-family-sf_pro_display-thin
  font-size: 16.6px
  font-style: normal
  font-weight: 100

.sfprotext-regular-normal-star-dust-2-5px
  color: $star-dust
  font-family: $font-family-sf_pro_text-regular
  font-size: 2.5px
  font-style: normal
  font-weight: 400

.roboto-medium-apple-1-5px
  color: $apple
  font-family: $font-family-roboto
  font-size: 1.5px
  font-style: normal
  font-weight: 500

.roboto-medium-cadet-blue-1-5px
  color: $cadet-blue-2
  font-family: $font-family-roboto
  font-size: 1.5px
  font-style: normal
  font-weight: 500

.roboto-medium-shark-1-7px
  color: $shark
  font-family: $font-family-roboto
  font-size: 1.7px
  font-style: normal
  font-weight: 500

.roboto-medium-white-1-2px
  color: $white
  font-family: $font-family-roboto
  font-size: 1.2px
  font-style: normal
  font-weight: 500

.roboto-normal-cadet-blue-1-7px,
.roboto-normal-cadet-blue-1-7px-2
  color: $cadet-blue-2
  font-family: $font-family-roboto
  font-size: 1.7px
  font-style: normal
  font-weight: 400

.montserrat-bold-steel-gray-12px
  color: $steel-gray
  font-family: $font-family-montserrat
  font-size: 12px
  font-style: normal
  font-weight: 700

.montserrat-normal-white-32px
  color: $white
  font-family: $font-family-montserrat
  font-size: 32px
  font-style: normal
  font-weight: 400

.montserrat-medium-white-9-6px
  color: $white
  font-family: $font-family-montserrat
  font-size: 9.6px
  font-style: normal
  font-weight: 500

.roboto-normal-fiord-5-2px
  color: $fiord-2
  font-family: $font-family-roboto
  font-size: 5.2px
  font-style: normal
  font-weight: 400

.sfprotext-semi-bold-mine-shaft-4-2px
  color: $mine-shaft
  font-family: $font-family-sf_pro_text-semibold
  font-size: 4.2px
  font-style: normal
  font-weight: 600

.sfpro-regular-normal-white-4-1px
  color: $white
  font-family: $font-family-sf_pro-regular
  font-size: 4.1px
  font-style: normal
  font-weight: 400

.sfpro-regular-normal-white-4-4px
  color: $white
  font-family: $font-family-sf_pro-regular
  font-size: 4.4px
  font-style: normal
  font-weight: 400

.sfpro-regular-normal-white-2-9px
  color: $white
  font-family: $font-family-sf_pro-regular
  font-size: 2.9px
  font-style: normal
  font-weight: 400

.roboto-medium-fiord-8-2px
  color: $fiord-2
  font-family: $font-family-roboto
  font-size: 8.2px
  font-style: normal
  font-weight: 500

.roboto-normal-fiord-5-3px
  color: $fiord-2
  font-family: $font-family-roboto
  font-size: $font-size-xxs
  font-style: normal
  font-weight: 400

.roboto-bold-fiord-5-3px
  color: $fiord-2
  font-family: $font-family-roboto
  font-size: $font-size-xxs
  font-style: normal
  font-weight: 700

.montserrat-normal-white-14-7px
  color: $white
  font-family: $font-family-montserrat
  font-size: 14.7px
  font-style: normal
  font-weight: 400

.montserrat-normal-white-16px
  color: $white
  font-family: $font-family-montserrat
  font-size: $font-size-s
  font-style: normal
  font-weight: 400

.montserrat-semi-bold-blueberry-16px
  color: $blueberry
  font-family: $font-family-montserrat
  font-size: $font-size-s
  font-style: normal
  font-weight: 600

.roboto-normal-white-4-3px
  color: $white
  font-family: $font-family-roboto
  font-size: 4.3px
  font-style: normal
  font-weight: 400

.roboto-medium-white-8-5px
  color: $white
  font-family: $font-family-roboto
  font-size: 8.5px
  font-style: normal
  font-weight: 500

.roboto-normal-white-4-6px
  color: $white-2
  font-family: $font-family-roboto
  font-size: 4.6px
  font-style: normal
  font-weight: 400

.roboto-medium-white-4-3px
  color: $white
  font-family: $font-family-roboto
  font-size: 4.3px
  font-style: normal
  font-weight: 500

.roboto-bold-rose-3-7px
  color: $rose
  font-family: $font-family-roboto
  font-size: 3.7px
  font-style: normal
  font-weight: 700

.montserrat-semi-bold-regent-gray-16px
  color: $regent-gray
  font-family: $font-family-montserrat
  font-size: $font-size-s
  font-style: normal
  font-weight: 600

.roboto-normal-white-3-5px
  color: $white
  font-family: $font-family-roboto
  font-size: 3.5px
  font-style: normal
  font-weight: 400

.roboto-medium-white-6-9px
  color: $white
  font-family: $font-family-roboto
  font-size: 6.9px
  font-style: normal
  font-weight: 500

.roboto-normal-white-3-7px
  color: $white-2
  font-family: $font-family-roboto
  font-size: 3.7px
  font-style: normal
  font-weight: 400

.roboto-medium-white-3-5px
  color: $white
  font-family: $font-family-roboto
  font-size: 3.5px
  font-style: normal
  font-weight: 500

.roboto-bold-rose-3px
  color: $rose
  font-family: $font-family-roboto
  font-size: 3px
  font-style: normal
  font-weight: 700

.roboto-normal-white-7-7px
  color: $white
  font-family: $font-family-roboto
  font-size: 7.7px
  font-style: normal
  font-weight: 400

.roboto-medium-white-15-3px
  color: $white
  font-family: $font-family-roboto
  font-size: 15.3px
  font-style: normal
  font-weight: 500

.roboto-normal-white-8-3px
  color: $white-2
  font-family: $font-family-roboto
  font-size: 8.3px
  font-style: normal
  font-weight: 400

.roboto-medium-white-7-7px
  color: $white
  font-family: $font-family-roboto
  font-size: 7.7px
  font-style: normal
  font-weight: 500

.roboto-bold-rose-6-7px
  color: $rose
  font-family: $font-family-roboto
  font-size: 6.7px
  font-style: normal
  font-weight: 700

.roboto-medium-steel-gray-12-5px
  color: $steel-gray
  font-family: $font-family-roboto
  font-size: 12.5px
  font-style: normal
  font-weight: 500

.roboto-medium-white-8-6px
  color: $white
  font-family: $font-family-roboto
  font-size: 8.6px
  font-style: normal
  font-weight: 500

.roboto-normal-white-8-6px
  color: $white-3
  font-family: $font-family-roboto
  font-size: 8.6px
  font-style: normal
  font-weight: 400

.roboto-medium-white-9-6px
  color: $white
  font-family: $font-family-roboto
  font-size: 9.6px
  font-style: normal
  font-weight: 500

.roboto-normal-white-9-6px
  color: $white-3
  font-family: $font-family-roboto
  font-size: 9.6px
  font-style: normal
  font-weight: 400

.sfprotext-semi-bold-white-8-9px
  color: $white
  font-family: $font-family-sf_pro_text-semibold
  font-size: 8.9px
  font-style: normal
  font-weight: 600

.sfprotext-regular-normal-white-5-7px
  color: $white
  font-family: $font-family-sf_pro_text-regular
  font-size: 5.7px
  font-style: normal
  font-weight: 400

.sfprotext-regular-normal-white-7-2px
  color: $white
  font-family: $font-family-sf_pro_text-regular
  font-size: 7.2px
  font-style: normal
  font-weight: 400

.sfprotext-regular-normal-white-4-8px
  color: $white
  font-family: $font-family-sf_pro_text-regular
  font-size: 4.8px
  font-style: normal
  font-weight: 400

.sfprotext-semi-bold-white-4-8px
  color: $white
  font-family: $font-family-sf_pro_text-semibold
  font-size: 4.8px
  font-style: normal
  font-weight: 600

.roboto-normal-white-4-6px-2
  color: $white
  font-family: $font-family-roboto
  font-size: 4.6px
  font-style: normal
  font-weight: 400

.roboto-medium-white-9-2px
  color: $white
  font-family: $font-family-roboto
  font-size: 9.2px
  font-style: normal
  font-weight: 500

.roboto-normal-white-5px
  color: $white-2
  font-family: $font-family-roboto
  font-size: 5px
  font-style: normal
  font-weight: 400

.roboto-medium-white-4-6px
  color: $white
  font-family: $font-family-roboto
  font-size: 4.6px
  font-style: normal
  font-weight: 500

.roboto-bold-rose-4px
  color: $rose
  font-family: $font-family-roboto
  font-size: 4px
  font-style: normal
  font-weight: 700

.sfprotext-semi-bold-white-5-1px
  color: $white
  font-family: $font-family-sf_pro_text-semibold
  font-size: 5.1px
  font-style: normal
  font-weight: 600

.sfprotext-regular-normal-white-3-5px
  color: $white
  font-family: $font-family-sf_pro_text-regular
  font-size: 3.5px
  font-style: normal
  font-weight: 400

.sfprotext-regular-normal-white-4-4px
  color: $white
  font-family: $font-family-sf_pro_text-regular
  font-size: 4.4px
  font-style: normal
  font-weight: 400

.sfprotext-regular-normal-white-2-9px
  color: $white
  font-family: $font-family-sf_pro_text-regular
  font-size: 2.9px
  font-style: normal
  font-weight: 400

.roboto-medium-white-3-8px
  color: $white
  font-family: $font-family-roboto
  font-size: 3.8px
  font-style: normal
  font-weight: 500

.roboto-normal-white-3-8px
  color: $white-3
  font-family: $font-family-roboto
  font-size: 3.8px
  font-style: normal
  font-weight: 400

.sfprotext-semi-bold-white-6-3px
  color: $white
  font-family: $font-family-sf_pro_text-semibold
  font-size: 6.3px
  font-style: normal
  font-weight: 600

.roboto-medium-steel-gray-15-3px
  color: $steel-gray
  font-family: $font-family-roboto
  font-size: 15.3px
  font-style: normal
  font-weight: 500

.roboto-bold-manatee-1-7px
  color: $manatee
  font-family: $font-family-roboto
  font-size: 1.7px
  font-style: normal
  font-weight: 700

.roboto-medium-shark-3-2px
  color: $shark
  font-family: $font-family-roboto
  font-size: 3.2px
  font-style: normal
  font-weight: 500

.roboto-medium-buttercup-1-5px
  color: $buttercup
  font-family: $font-family-roboto
  font-size: 1.5px
  font-style: normal
  font-weight: 500

.roboto-medium-shark-2-4px
  color: $shark
  font-family: $font-family-roboto
  font-size: 2.4px
  font-style: normal
  font-weight: 500

.sfprotext-semi-bold-white-4-2px
  color: $white
  font-family: $font-family-sf_pro_text-semibold
  font-size: 4.2px
  font-style: normal
  font-weight: 600

.montserrat-bold-white-36px
  color: $white
  font-family: $font-family-montserrat
  font-size: $font-size-xl
  font-style: normal
  font-weight: 700

.roboto-normal-fiord-8-4px
  color: $fiord-2
  font-family: $font-family-roboto
  font-size: 8.4px
  font-style: normal
  font-weight: 400

.sfprotext-semi-bold-mine-shaft-6-8px
  color: $mine-shaft
  font-family: $font-family-sf_pro_text-semibold
  font-size: 6.8px
  font-style: normal
  font-weight: 600

.sfuidisplay-regular-normal-white-6-6px
  color: $white
  font-family: $font-family-sf_ui_display-regular
  font-size: 6.6px
  font-style: normal
  font-weight: 400

.sfuidisplay-regular-normal-white-7-2px
  color: $white
  font-family: $font-family-sf_ui_display-regular
  font-size: 7.2px
  font-style: normal
  font-weight: 400

.sfuidisplay-regular-normal-white-4-6px
  color: $white
  font-family: $font-family-sf_ui_display-regular
  font-size: 4.6px
  font-style: normal
  font-weight: 400

.roboto-medium-fiord-13-2px
  color: $fiord-2
  font-family: $font-family-roboto
  font-size: 13.2px
  font-style: normal
  font-weight: 500

.roboto-normal-fiord-8-5px
  color: $fiord-2
  font-family: $font-family-roboto
  font-size: 8.5px
  font-style: normal
  font-weight: 400

.roboto-bold-fiord-8-5px
  color: $fiord-2
  font-family: $font-family-roboto
  font-size: 8.5px
  font-style: normal
  font-weight: 700

.roboto-medium-shark-7-3px
  color: $shark
  font-family: $font-family-roboto
  font-size: 7.3px
  font-style: normal
  font-weight: 500

.roboto-normal-white-5-1px
  color: $white
  font-family: $font-family-roboto
  font-size: 5.1px
  font-style: normal
  font-weight: 400

.montserrat-medium-regent-gray-12px
  color: $regent-gray
  font-family: $font-family-montserrat
  font-size: 12px
  font-style: normal
  font-weight: 500

.montserrat-bold-french-gray-61-3px
  color: $french-gray
  font-family: $font-family-montserrat
  font-size: 61.3px
  font-style: normal
  font-weight: 700

.montserrat-bold-french-gray-15-3px
  color: $french-gray
  font-family: $font-family-montserrat
  font-size: 15.3px
  font-style: normal
  font-weight: 700

.montserrat-bold-french-gray-63px
  color: $french-gray
  font-family: $font-family-montserrat
  font-size: 63px
  font-style: normal
  font-weight: 700

.montserrat-bold-french-gray-15-8px
  color: $french-gray
  font-family: $font-family-montserrat
  font-size: 15.8px
  font-style: normal
  font-weight: 700

.montserrat-medium-white-8-5px
  color: $white
  font-family: $font-family-montserrat
  font-size: 8.5px
  font-style: normal
  font-weight: 500

.montserrat-medium-white-7-4px
  color: $white
  font-family: $font-family-montserrat
  font-size: 7.4px
  font-style: normal
  font-weight: 500

.montserrat-medium-steel-gray-5-7px
  color: $steel-gray
  font-family: $font-family-montserrat
  font-size: 5.7px
  font-style: normal
  font-weight: 500

.montserrat-bold-black-7px
  color: $black
  font-family: $font-family-montserrat
  font-size: 7px
  font-style: normal
  font-weight: 700

.montserrat-semi-bold-black-5-7px
  color: $black
  font-family: $font-family-montserrat
  font-size: 5.7px
  font-style: normal
  font-weight: 600

.montserrat-bold-french-gray-46-1px
  color: $french-gray
  font-family: $font-family-montserrat
  font-size: 46.1px
  font-style: normal
  font-weight: 700

.montserrat-bold-french-gray-11-5px
  color: $french-gray
  font-family: $font-family-montserrat
  font-size: 11.5px
  font-style: normal
  font-weight: 700

.montserrat-bold-french-gray-47-4px
  color: $french-gray
  font-family: $font-family-montserrat
  font-size: 47.4px
  font-style: normal
  font-weight: 700

.montserrat-bold-french-gray-11-8px
  color: $french-gray
  font-family: $font-family-montserrat
  font-size: 11.8px
  font-style: normal
  font-weight: 700

.roboto-normal-white-3-1px
  color: $white
  font-family: $font-family-roboto
  font-size: 3.1px
  font-style: normal
  font-weight: 400

.sfprotext-semi-bold-white-3-6px
  color: $white
  font-family: $font-family-sf_pro_text-semibold
  font-size: 3.6px
  font-style: normal
  font-weight: 600

.montserrat-normal-regent-gray-11-3px
  color: $regent-gray
  font-family: $font-family-montserrat
  font-size: 11.3px
  font-style: normal
  font-weight: 400

.sfprotext-semi-bold-white-10-5px
  color: $white
  font-family: $font-family-sf_pro_text-semibold
  font-size: 10.5px
  font-style: normal
  font-weight: 600

.border-14-7px-white
  border: 14.7px solid $white

.border-0-2px-white
  border: 0.2px solid $white

.border-2px-steel-gray
  border: 2px solid $steel-gray

.border-2px-regent-gray
  border: 2px solid $regent-gray

.border-11px-white
  border: 11.0px solid $white

.border-8px-white
  border: 8.0px solid $white