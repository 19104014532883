@import 'variables'

.x3-page1
  align-items: center
  background-size: 100% 100%
  display: flex
  flex-direction: column
  height: 900px
  overflow: hidden
  padding: 24px 0
  position: relative

.frame-1321314091
  align-items: flex-end
  display: flex
  flex-direction: column
  margin-top: 118px
  min-height: 745px
  width: 614px

.frame-1321313942
  align-items: center
  display: flex
  flex-direction: column
  min-height: 235px
  width: 614px

.fonecom-outdoor-display
  letter-spacing: 0
  min-height: 176px
  min-width: 614px
  text-align: center

.make-an-unforgettabl-2
  letter-spacing: 0
  line-height: 32px
  margin-bottom: -5px
  margin-top: 32px
  min-height: 27px
  text-align: center
  white-space: nowrap
  width: 512px

.overlap-group2-3
  background-size: 100% 100%
  height: 791px
  margin-right: -18.58px
  margin-top: 35px
  position: relative
  width: 480px

.front-intercom-1-1
  height: 632px
  left: 12px
  position: absolute
  top: 70px
  width: 433px

.slide-169-14-2
  align-items: flex-start
  background-color: $black
  display: flex
  height: 259px
  left: -75px
  overflow: hidden
  position: absolute
  top: 240px
  transform: rotate(-90.00deg)
  width: 460px

.overlap-group1-7
  height: 330px
  margin-left: 14px
  margin-top: -76.26px
  position: relative
  width: 523px

.unsplash-6z-fvl4-yua-m-2
  height: 308px
  left: 63px
  object-fit: cover
  position: absolute
  top: 0
  width: 460px

.group-1359695-2
  align-items: flex-start
  display: flex
  height: 303px
  left: 117px
  min-width: 216px
  position: absolute
  top: 54px
  transform: rotate(-90.00deg)

.frame-1359702-2
  -webkit-backdrop-filter: blur(34.48px) brightness(100%)
  align-items: flex-start
  backdrop-filter: blur(34.48px) brightness(100%)
  background-color: $black-2
  border-radius: 8.27px
  display: flex
  flex-direction: column
  margin-left: -259px
  margin-top: -43.65px
  min-height: 303px
  padding: 27.8px 46.0px
  transform: rotate(90.00deg)
  width: 216px

.address-2
  align-self: center
  height: 9px
  letter-spacing: 0
  margin-left: 0.24px
  text-align: center
  width: 73px

.welcome-to-our-building-2
  height: 36px
  letter-spacing: 0
  margin-top: 32px
  text-align: center
  width: 124px

.circle-button-2
  align-items: flex-start
  align-self: flex-end
  display: flex
  height: 78px
  justify-content: center
  margin-bottom: 24.46px
  margin-left: 35px
  min-width: 78px
  transform: rotate(90.00deg)

.overlap-group-10
  background-color: $royal-blue-2
  border-radius: 39.97px
  height: 80px
  margin-top: -1px
  position: relative
  width: 80px

.oval-3-2
  background-color: $royal-blue
  border-radius: 29.66px/29.59px
  height: 59px
  left: 10px
  position: absolute
  top: 10px
  width: 59px

.combined-shape-2
  height: 22px
  left: 32px
  position: absolute
  top: 29px
  width: 16px

.group-1359696-2
  align-items: center
  display: flex
  height: 28px
  left: -95px
  min-width: 218px
  position: absolute
  top: 192px
  transform: rotate(-90.00deg)

.flex-col-6
  align-items: center
  align-self: flex-end
  display: flex
  flex-direction: column
  margin-bottom: -94.11px
  margin-left: -122px
  min-height: 105px
  width: 216px

.search-2
  height: 10px
  letter-spacing: 0
  margin-left: 39.99px
  text-align: center
  width: 26px

.rectangle-352-2
  -webkit-backdrop-filter: blur(34.47px) brightness(100%)
  backdrop-filter: blur(34.47px) brightness(100%)
  background-color: $black-2
  border-radius: 8.27px
  height: 28px
  margin-top: 68px
  transform: rotate(90.00deg)
  width: 216px

.akar-iconssearch-2
  align-items: flex-start
  display: flex
  height: 12px
  margin-left: 90px
  margin-top: 0
  padding: 1.0px 1.0px
  transform: rotate(90.00deg)
  width: 12px

.group-3
  height: 9px
  width: 9px

.intercom-info-plaque-2
  -webkit-backdrop-filter: blur(27.33px) brightness(100%)
  align-items: flex-start
  backdrop-filter: blur(27.33px) brightness(100%)
  background-color: $black-2
  border-radius: 8.15px
  display: flex
  height: 34px
  left: 290px
  min-width: 216px
  padding: 7.2px 7.2px
  position: absolute
  top: 189px
  transform: rotate(-90.00deg)

.unsplasht2-sai-aq-ip-i-2
  align-self: flex-end
  height: 20px
  width: 20px

.group-1359704-2
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-left: 6px
  margin-top: 0.24px
  min-height: 18px
  width: 91px

.company-name-2
  height: 9px
  letter-spacing: 0

.new-york-ny-4715-5-avenue-2
  height: 8px
  letter-spacing: 0
  margin-top: 1px

.phone-2
  letter-spacing: 0
  margin-left: 25px
  margin-top: 1.2px
  min-height: 8px
  min-width: 55px
  text-align: right

.group-1359703-2
  -webkit-backdrop-filter: blur(23.98px) brightness(100%)
  align-items: flex-start
  backdrop-filter: blur(23.98px) brightness(100%)
  background-color: $black-2
  border-radius: 8.15px
  display: flex
  flex-direction: column
  left: 33px
  min-height: 216px
  padding: 26.1px 6.7px
  position: absolute
  top: 98px
  width: 35px

.rectangle-button-14
  align-items: flex-start
  background-size: 100% 100%
  display: flex
  height: 21px
  justify-content: flex-end
  margin-left: 0
  margin-top: 8px
  min-width: 21px
  padding: 1.2px 1.4px
  transform: rotate(-90.00deg)

.icon-concierge-2
  height: 18px
  width: 18px

.rectangle-button-15
  align-items: flex-start
  background-size: 100% 100%
  display: flex
  height: 21px
  margin-left: 0
  margin-top: 8px
  min-width: 21px
  padding: 6.4px 4.4px
  transform: rotate(-90.00deg)

.number-2
  height: 8px
  letter-spacing: 0
  transform: rotate(-0.57deg)
  width: 12px

.logo-1-color-2
  align-items: flex-start
  display: flex
  flex-direction: column
  left: 408px
  min-height: 12px
  padding: 0.0px 0.1px
  position: absolute
  top: 295px
  transform: rotate(-90.00deg)
  width: 57px

.union-2
  height: 2px
  margin-left: 0.08px
  width: 33px

.subtract-2
  height: 7px
  margin-top: 2px
  width: 57px

