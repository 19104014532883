
@import 'rem'
@import 'variables'

.staff-card
    .content
        display: grid
        // grid-template: repeat(auto-fit, minmax(2rem, auto)) / 100%
        grid-template-rows: rem(128px) minmax(2rem, max-content) minmax(2rem, max-content) 1.5rem
        grid-template-columns: 100%
        height: 100%
        row-gap: 1rem
        & > h2
            font-size: $font-size-l !important
            line-height: $font-size-xl !important
            font-weight: 600
        & > div, h2, h3
            padding: 0rem !important
        .userpic
            margin: 0 auto
            width: rem(128px)
            height: rem(128px)
            border-radius: rem(64px)
            background-color: $black
            background-size: rem(132px)
            grid-row: 1