@import 'variables'

.x5-page6
  align-items: flex-start
  background-size: 100% 100%
  display: flex

.overlap-group4-7
  height: 900px
  position: relative

.frame-1359712-2
  align-items: center
  display: flex
  flex-direction: column
  height: 900px
  left: 0
  padding: 0 140.0px
  position: absolute
  top: 0

.the-intercom-reimagined-4
  letter-spacing: 0
  margin-left: 1.0px
  margin-top: -346px
  min-height: 176px
  min-width: 503px
  text-align: center

.flex-row-24
  align-items: center
  align-self: flex-end
  display: flex
  height: 606px
  margin-top: 208px
  min-width: 819px

.ellipse-115-4
  background-color: $white
  border-radius: 15.78px
  height: 32px
  margin-bottom: 207.34px
  transform: rotate(-105.00deg)
  width: 32px

.group-1359873
  align-items: center
  background-color: $white-2
  border-radius: 40px
  display: flex
  flex-direction: column
  margin-left: 220px
  min-height: 606px
  padding: 133px 0
  width: 568px

.as-a-service-at-your-service
  letter-spacing: 0
  line-height: 52px
  min-height: 104px
  width: 472px

.whether-youre-in-se
  letter-spacing: 0
  line-height: 32px
  margin-top: 20px
  min-height: 216px
  width: 472px

.frame-1359971-5
  align-items: center
  display: flex
  height: 44px
  left: 40px
  min-width: 1360px
  position: absolute
  top: 24px

.frame-1321314061-29
  height: 17px
  width: 192px

.frame-1359884-30
  align-items: flex-start
  display: flex
  height: 32px
  margin-left: 153px
  min-width: 574px

.intercoms-32
  letter-spacing: 0
  line-height: 32px
  min-height: 32px
  min-width: 72px
  white-space: nowrap

.video-concierge-41
  letter-spacing: 0
  line-height: 32px
  margin-left: 62px
  min-height: 32px
  min-width: 116px
  white-space: nowrap

.help-as-a-service-42
  letter-spacing: 0
  line-height: 32px
  margin-left: 62px
  min-height: 32px
  min-width: 122px
  white-space: nowrap

.contact-us-30
  letter-spacing: 0
  line-height: 32px
  margin-left: 62px
  min-height: 32px
  white-space: nowrap

.overlap-group3-16
  height: 443px
  left: 58px
  position: absolute
  top: 224px
  width: 600px

.group-1359904-1
  align-items: flex-start
  display: flex
  flex-direction: column
  left: 311px
  min-height: 222px
  position: absolute
  top: 79px
  width: 159px

.ellipse-117-2
  background-color: $white
  border-radius: 9.25px
  height: 19px
  margin-left: 26.76px
  margin-top: 1px
  opacity: 0.8
  transform: rotate(-105.00deg)
  width: 19px

.ellipse-116-3
  background-color: $white
  border-radius: 6.2px
  height: 12px
  margin-left: 16.08px
  margin-top: 15px
  opacity: 0.5
  transform: rotate(-105.00deg)
  width: 12px

.group-1359906-2
  align-items: flex-start
  display: flex
  height: 211px
  left: 382px
  min-width: 218px
  position: absolute
  top: 203px

.ellipse-117-3
  align-self: flex-end
  background-color: $white
  border-radius: 6.89px
  height: 14px
  margin-bottom: 0
  opacity: 0.5
  width: 14px

.overlap-group1-37
  border-radius: 102.82px
  height: 219px
  margin-top: -14.69px
  position: relative
  width: 219px

.ellipse-115-5
  background-color: $white
  border-radius: 15.78px
  height: 32px
  left: 10px
  position: absolute
  top: 167px
  width: 32px

.overlap-group2-19
  border-radius: 102.82px
  height: 219px
  left: 100px
  position: absolute
  top: 0
  width: 219px

.ellipse-113-1
  background-color: $white
  border-radius: 11.48px
  height: 23px
  left: 168px
  position: absolute
  top: 182px
  width: 23px

.frame-1321313962-3
  align-items: flex-start
  border-radius: 102.82px
  display: flex
  height: 219px
  left: 0
  overflow: hidden
  padding: 0 0.0px
  position: absolute
  top: 0
  width: 219px

.overlap-group-88
  background-position: 50% 50%
  background-size: cover
  height: 189px
  margin-top: 0
  position: relative
  width: 189px

.rectangle-441-7
  height: 58px
  left: 7px
  position: absolute
  top: 131px
  width: 175px

.title-7
  left: 35px
  letter-spacing: 0
  position: absolute
  text-align: center
  top: 157px
  width: 119px

