@import 'variables'

.container-footer
    width: 100%
    display: flex
    flex-direction: column
    justify-content: space-between
    padding: 3rem 2rem
    background-color: #212632
    align-items: center

    @media screen and (max-width: 989px)
        height: 100%
        align-items: flex-start
        div
            flex-direction: column
            align-items: flex-start

    .container-footer-section
        @media screen and (max-width: 989px)
            display: flex
            flex-direction: column
            min-width: unset
            max-width: unset