@import 'mixins'
@import 'variables'
@import 'rem'

.content-screen
    display: flex
    align-items: center
    justify-items: center
    position: relative
    justify-content: center
    width: 100%
    height: 100%

    &.with-background
        @include desktop
            min-height: 100vh

    .childnodes
        width: 100%
    .fullscreen-content
        padding: 0 0 2rem 0
        & > *
            text-align: center
            padding: 1rem 0
        h1
            font-weight: 700
            @include desktop
                font-size: $font-size-xxl
            @include mobile
                font-size: $font-size-xl
        h3
            color: $gray
            font-size: $font-size-xl
            display: flex
            flex-direction: column
            color: $black
            font-weight: 700
        div, span
            & > *
            display: block
            font-weight: 400
            line-height: $font-size-l
            size: $font-size-m
            color: $regent-gray

            & > *
                padding: 1rem 0
            span
                display: block
            & >  b
                    font-weight: 600
                
    @include desktop
        margin: 0 auto
    

    &.multicard
        .childnodes 
            display: grid
            column-gap: 1rem
            row-gap: 1rem
            .fullscreen-content
                grid-column-start: 1
                grid-column-end: 4
            @include desktop
                width: rem(1280px)
                grid-template-columns: repeat(auto-fit, minmax(getGridCardSize(var(--stack-size)), 1fr))
                &.two-panel 
                    .card:nth-child(odd)
                        justify-self: end
                    .card:nth-child(even)
                        justify-self: start
                    
            @include mobile
                grid-template-rows: repeat(auto-fit, minmax(20rem, 1fr))
                align-items: center
                justify-items: center
    
    &.screen-splash
        display: grid
        align-items: center

        @include desktop
            grid-template-columns: 1fr

            .fullscreen-content
                left: 0
                right: 0
                margin-right: auto
                margin-left: auto

        @include mobile
            grid-template-columns: 1fr
            justify-content: center
            div
                align-items: center
                margin: 0 auto
            .overlay-wrapper
                display: flex
                flex-direction: column
                position: relative
                align-content: flex-start
                justify-content: flex-start
                margin: 0 auto
                grid-row: 2
            // .overlay-wrapper
            //     position: absolute
            //     bottom: rem(160px)
            //     height: fit-content
            //     .splash-backdrop
            //         position: absolute
            //         width: 100%
            //         height: rem(320px)
            //         background-position-x: center
            //         background-position-y: center
            //         bottom: 0
        .splash-backdrop
            @include desktop
                width: 100%
                height: 100%
                min-height: rem(600px)
                background-size: auto rem(700px)
                // background-position-y: rem(300px)
                background-position-x: center
                position: inherit



.overlay-wrapper
    position: absolute
    width: 100%
    height: 100%
    bottom: 0
    right: 0
    pointer-events: none
    @include desktop
        & > *
            width: 100%
            height: 100%
            background-size: auto rem(450px)
            background-position-y: bottom
            background-position-x: center
            position: inherit
    @include mobile
