@import 'variables'

.x5-page8
  align-items: center
  background-position: 50% 50%
  background-size: cover
  display: flex
  flex-direction: column
  height: 900px
  padding: 24px 0

.frame-1321313936-21
  align-items: center
  display: flex
  height: 44px
  min-width: 1360px
  position: relative

.frame-1321314061-32
  height: 17px
  width: 192px

.frame-1321314154
  align-items: center
  display: flex
  flex-direction: column
  margin-top: 118px
  min-height: 499px
  width: 765px

.get-started-free-1
  letter-spacing: 0
  line-height: 52px
  min-height: 52px
  min-width: 310px
  white-space: nowrap

.frame-1321313976-2
  align-items: flex-start
  display: flex
  height: 383px
  margin-top: 64px
  min-width: 765px

.frame-1321314007-2
  align-items: flex-start
  background-color: $white-2
  border-radius: 40px
  display: flex
  flex-direction: column
  min-height: 383px
  position: relative
  width: 371px

.my-qr-fonecom-3
  letter-spacing: 0
  margin-left: 32px
  margin-top: 27px
  min-height: 29px

.register-now-2
  letter-spacing: 0
  line-height: 32px
  margin-left: 32px
  margin-top: 83px
  min-height: 32px
  white-space: nowrap

.frame-1321314010-2
  align-items: flex-start
  background-color: $white-2
  border-radius: 40px
  display: flex
  flex-direction: column
  margin-left: 24px
  min-height: 383px
  padding: 32px 26.4px
  position: relative
  width: 370px

.help-as-a-service-45
  letter-spacing: 0
  margin-left: 5.57px
  margin-top: 16px
  min-height: 29px

.buy-now-8
  letter-spacing: 0
  line-height: 32px
  margin-left: 5.57px
  margin-top: 37px
  min-height: 32px
  white-space: nowrap

.try-for-30-days-4
  letter-spacing: 0
  line-height: 32px
  margin-left: 5.57px
  margin-top: 14px
  min-height: 32px
  white-space: nowrap

