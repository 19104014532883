.frame-1321313936
  align-items: flex-end
  display: flex
  height: 68px
  margin-right: 1.0px
  min-width: 1441px
  padding: 0 40px
  position: relative

.frame-1321314061
  align-self: center
  height: 17px
  margin-top: 24.0px
  width: 192px

