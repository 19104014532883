.header-wrapper
    display: flex
    align-items: flex-start
    .header-menuelement
        color: #4f4f6a
        

.frame-1359884
  align-items: flex-start
  display: flex
  height: 32px
  margin-bottom: 6px
  margin-left: 153px
  min-width: 574px

.intercoms-2
  letter-spacing: 0
  line-height: 32px
  min-height: 32px
  min-width: 72px
  white-space: nowrap

.video-concierge-1
  letter-spacing: 0
  line-height: 32px
  margin-left: 62px
  min-height: 32px
  min-width: 116px
  white-space: nowrap

.help-as-a-service-1
  letter-spacing: 0
  line-height: 32px
  margin-left: 62px
  min-height: 32px
  min-width: 122px
  white-space: nowrap

.contact-us
  letter-spacing: 0
  line-height: 32px
  margin-left: 62px
  min-height: 32px
  white-space: nowrap

.frame-1359884.frame-1359884-1
  margin-bottom: unset

