.frame-1321313918
  align-items: flex-start
  display: flex
  flex-direction: column
  min-height: 330px
  width: 472px

.saying-hello-to-futu
  letter-spacing: 0
  line-height: 52px
  min-height: 156px
  width: 472px

.so-say-goodbye-to-bu
  letter-spacing: 0
  line-height: 32px
  margin-top: 20px
  min-height: 154px
  width: 472px

.frame-1321313918.frame-1321313896-1
  margin-top: 96px
  min-height: 306px

.frame-1321313918.frame-1321313896-1 .so-say-goodbye-to-bu
  min-height: 254px

