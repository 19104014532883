@import 'variables'

.frame-1321313958
  display: flex
  flex-direction: column
  justify-content: space-between


.mobile-scheme
  display: flex
  width: 100%
  justify-content: space-evenly
  div
    display: flex
    align-items: center
  div:nth-child(2)
    &::after,  &::before
      display: block
      content: ''
      width: 4rem
      height: 100%
      background: url('/static/img/arrow.png')
      background-repeat: no-repeat
      background-position: center

.x2-page6
  align-items: flex-start
  background-size: 100% 100%
  display: flex
  overflow-x: hidden

.flex-col-42
  align-items: center
  display: flex
  flex-direction: column
  margin-top: 24px

.frame-1321314084
  align-items: center
  display: flex
  flex-direction: column
  min-height: 786px
  position: relative
  width: 1360px

.frame-1321314083
  align-items: center
  display: flex
  flex-direction: column
  margin-top: 60px
  min-height: 682px
  width: 1160px

.how-it-works
  letter-spacing: 0
  line-height: 52px
  min-height: 52px
  min-width: 268px
  text-align: center
  white-space: nowrap

.frame-1321314082
  align-items: flex-start
  display: flex
  height: 606px
  margin-top: 24px
  min-width: 1160px

.frame-1321313958
  align-items: flex-end
  background-color: $white-2
  border-radius: 40px
  display: flex
  flex-direction: column
  min-height: 606px
  padding: 48px 39px
  position: relative
  width: 568px

.frame-1359969
  align-items: flex-start
  display: flex
  margin-right: 37.32px
  margin-top: 159px
  min-width: 415px

.overlap-group4-14
  align-items: flex-start
  background-size: 100% 100%
  display: flex
  height: 192px
  margin-top: 0
  min-width: 89px
  padding: 66.0px 22px

.frame-1359979-12
  background-size: 100% 100%
  height: 31px
  width: 30px

.x-container
  align-self: flex-end
  height: 194px
  margin-bottom: 0
  margin-left: 14px
  position: relative
  width: 206px

.group-1359765
  align-items: flex-start
  background-size: 100% 100%
  display: flex
  height: 194px
  left: 58px
  min-width: 90px
  padding: 29px 15px
  position: absolute
  top: 0

.fill-24-36
  height: 5px
  width: 59px

.frame-1359980
  background-size: 100% 100%
  height: 1px
  left: 0
  position: absolute
  top: 93px
  width: 206px

.frame-1321313909-2
  align-items: flex-start
  border: 4.7px solid #3e3e42
  border-radius: 10px
  display: flex
  height: 204px
  margin-left: 9px
  margin-top: -4.73px
  min-width: 101px

.group-container-15
  height: 194px
  margin-left: -1px
  margin-top: -0.02px
  position: relative
  width: 93px

.group-1359755
  align-items: flex-start
  background-size: 100% 100%
  display: flex
  height: 175px
  justify-content: flex-end
  left: 1px
  min-width: 92px
  position: absolute
  top: 19px

.wc-video-call-with-chat
  height: 175px
  margin-top: 0
  width: 92px

.overlap-group2-28
  height: 19px
  left: 0
  position: absolute
  top: 0
  width: 93px

.rectangle-29-3
  background-color: #1a1919
  height: 19px
  left: 1px
  position: absolute
  top: 0
  width: 92px

.status-bar-3
  align-items: center
  display: flex
  height: 11px
  justify-content: flex-end
  left: 0
  min-width: 93px
  padding: 3.5px 3.6px
  position: absolute
  top: 0

.time-12
  align-self: flex-start
  letter-spacing: -0.06px
  min-height: 4px
  text-align: center
  width: 13px

.cellular-connection-10
  height: 3px
  margin-left: 55px
  margin-top: 0.49px
  width: 4px

.wifi-12
  height: 3px
  margin-left: 1px
  margin-top: 0.41px
  width: 4px

.battery-12
  height: 3px
  margin-left: 1px
  margin-top: 0.49px
  width: 6px

.group-18-4
  align-items: flex-start
  display: flex
  flex-direction: column
  left: 42px
  min-height: 86px
  position: absolute
  top: -30px
  transform: rotate(-90.00deg)
  width: 8px

.vector-12
  height: 1px
  margin-left: 1.33px
  margin-top: 2px
  width: 1px

.overlap-group1-51
  align-items: flex-start
  background-size: 100% 100%
  display: flex
  height: 25px
  justify-content: flex-end
  margin-left: 2.19px
  margin-top: 49px
  min-width: 3px
  padding: 4.5px 0
  position: relative

.overlap-group-124
  border-radius: 0.93px
  height: 8px
  margin-top: 1px
  position: relative
  width: 86px

.rectangle-5-3
  background-color: $mine-shaft
  border-radius: 0.93px
  height: 8px
  left: 0
  position: absolute
  top: 0
  width: 86px

.vector-13
  height: 4px
  left: 3px
  position: absolute
  top: 2px
  width: 3px

.frame-1321313957
  align-items: flex-end
  background-color: $white-2
  border-radius: 40px
  display: flex
  flex-direction: column
  margin-left: 24px
  min-height: 606px
  padding: 48px 39px
  position: relative
  width: 568px

.overlap-group1-52
  align-self: center
  height: 248px
  margin-right: 0.52px
  margin-top: 84px
  position: relative
  width: 435px

.group-1359868-6
  align-items: flex-start
  background-size: 100% 100%
  display: flex
  height: 223px
  left: 16px
  min-width: 419px
  padding: 13.3px 75.8px
  position: absolute
  top: 11px

.frame-1359980-1
  background-size: 100% 100%
  height: 178px
  width: 264px

.group-1359866-8
  height: 42px
  left: 350px
  position: absolute
  top: 0
  width: 42px

.incoming-call-1
  height: 59px
  left: 0
  position: absolute
  top: 46px
  width: 148px

.overlap-group-125
  height: 176px
  left: 334px
  position: absolute
  top: 73px
  width: 97px

.frame-16
  height: 159px
  left: 20px
  position: absolute
  top: 16px
  width: 77px

.put-screen-in-here-16
  align-items: flex-start
  background-color: $mirage
  border-radius: 8.72px
  display: flex
  height: 153px
  left: 4px
  min-width: 71px
  position: absolute
  top: 0

.intercom-mobile-door-only-6
  height: 153px
  margin-top: -0.5px
  width: 71px

.system-bar-white-7
  align-items: center
  display: flex
  height: 3px
  left: 8px
  min-width: 66px
  position: absolute
  top: 3px

.time-13
  letter-spacing: -0.07px
  min-height: 3px
  text-align: center
  width: 10px

.cellular-connection-11
  height: 2px
  margin-left: 41px
  margin-top: 0
  width: 3px

.wifi-13
  height: 2px
  margin-bottom: 0.06px
  margin-left: 1px
  width: 3px

.battery-13
  height: 2px
  margin-left: 1px
  margin-top: 0
  width: 5px

.home-indicator-white-16
  background-color: $white
  border-radius: 24.21px
  height: 1px
  left: 26px
  position: absolute
  top: 150px
  width: 25px

.screen-shine-15
  height: 152px
  left: 4px
  mix-blend-mode: screen
  position: absolute
  top: 1px
  width: 70px

.hardware-16
  align-items: flex-end
  display: flex
  height: 53px
  left: 0
  min-width: 78px
  position: absolute
  top: 0

.flex-col-43
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-bottom: 0
  min-height: 37px
  width: 1px

.switch-16
  background-color: $black
  height: 5px
  opacity: 0.5
  transform: rotate(-180deg)
  width: 1px

.volume-up-button-16
  background-color: $black
  height: 11px
  margin-top: 6px
  opacity: 0.5
  transform: rotate(-180deg)
  width: 1px

.volume-down-button-16
  background-color: $black
  height: 11px
  margin-top: 4px
  opacity: 0.5
  transform: rotate(-180deg)
  width: 1px

.notch-17
  align-items: flex-start
  align-self: flex-start
  background-size: 100% 100%
  display: flex
  height: 4px
  justify-content: flex-end
  margin-left: 23px
  margin-top: 0
  min-width: 30px
  padding: 0 8.3px

.speaker-14
  background-color: $black
  border-radius: 24.21px
  height: 1px
  margin-top: 0.38px
  opacity: 0.75
  width: 8px

.camera-13
  height: 2px
  margin-left: 1px
  margin-top: 0
  width: 2px

.power-button-16
  background-color: $black
  height: 18px
  margin-bottom: 3.77px
  margin-left: 23px
  opacity: 0.5
  width: 1px

.x2-page_7
  margin-top: 273px

.x2-page_7_mobile
  align-items: center
  background-size: 100% 100%
  display: flex
  margin-left: 72px
  margin-top: 1065px
  overflow: hidden
  padding: 10.3px 0
  position: relative
  width: 375px

.group-1359938-14
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-top: 52px
  min-height: 424px
  width: 327px

.dont-just-say-hello
  letter-spacing: 0
  line-height: 40px
  min-height: 160px
  width: 327px

.calling-qr-video-int
  letter-spacing: 0
  line-height: 32px
  margin-top: 12px
  min-height: 252px
  width: 327px

.group-container-16
  align-self: flex-end
  height: 476px
  margin-right: -708.03px
  margin-top: 52px
  position: relative
  width: 1035px

.group-1359689
  align-items: center
  display: flex
  flex-direction: column
  left: 938px
  min-height: 374px
  position: absolute
  top: 33px
  width: 97px

.frame-1091338
  align-items: center
  display: flex
  flex-direction: column
  min-height: 170px
  width: 97px

.navigation-panel,
.intercom-system
  letter-spacing: 0
  line-height: 150px
  margin-bottom: -133px
  min-height: 17px
  text-align: center
  white-space: nowrap
  width: 97px

.line-31-1
  height: 130px
  margin-left: 0.94px
  margin-top: 23px
  width: 2px

.i-phone-xs-space-grayscreen-1
  height: 176px
  margin-left: 0.94px
  margin-top: 28px
  object-fit: cover
  width: 83px

.group-1359683
  align-items: flex-start
  display: flex
  flex-direction: column
  left: 861px
  min-height: 345px
  position: absolute
  top: 74px
  width: 118px

.frame-1091338-1
  align-items: center
  display: flex
  flex-direction: column
  min-height: 113px
  width: 118px

.link-to-google-maps-google-directions
  letter-spacing: 0
  line-height: 150px
  margin-bottom: -116px
  min-height: 34px
  text-align: center
  white-space: nowrap
  width: 118px

.line-31-2
  height: 57px
  margin-left: 0.29px
  margin-top: 23px
  width: 1px

.i-phone-xs-space-graynewfrtre-1
  height: 199px
  margin-left: 4.71px
  margin-top: 33px
  width: 90px

.group-1359682
  align-items: flex-start
  display: flex
  flex-direction: column
  left: 797px
  min-height: 424px
  position: absolute
  top: 0
  width: 105px

.frame-1091340
  align-items: center
  display: flex
  flex-direction: column
  margin-left: 3.77px
  min-height: 195px
  width: 101px

.optional-qr-lock
  letter-spacing: 0
  line-height: 18.8px
  min-height: 19px
  text-align: center
  white-space: nowrap
  width: 101px

.line-31-3
  height: 154px
  margin-left: 0.29px
  margin-top: 23px
  width: 1px

.i-phone-xs-space-graynewnew-2frtre-1
  height: 215px
  margin-left: -9.42px
  margin-top: 14px
  width: 98px

.group-1359690
  align-items: flex-start
  display: flex
  flex-direction: column
  left: 708px
  min-height: 360px
  position: absolute
  top: 68px
  width: 116px

.frame-1091338-2
  align-items: center
  display: flex
  flex-direction: column
  min-height: 99px
  width: 116px

.pre-recorded-videos-youtube-mp4
  letter-spacing: 0
  line-height: 150px
  margin-bottom: -116px
  min-height: 34px
  text-align: center
  white-space: nowrap
  width: 116px

.line-31
  height: 42px
  margin-left: 1.0px
  margin-top: 23px
  width: 1px

.i-phone-xs-space-graynew-2
  height: 228px
  margin-left: -6.59px
  margin-top: 33px
  object-fit: cover
  width: 108px

.group-1359684
  align-items: flex-start
  display: flex
  flex-direction: column
  left: 623px
  min-height: 433px
  position: absolute
  top: 0
  width: 124px

.frame-1091338-3
  align-items: center
  display: flex
  flex-direction: column
  min-height: 173px
  width: 124px

.documents-images
  letter-spacing: 0
  line-height: 150px
  margin-bottom: -133px
  min-height: 17px
  text-align: center
  white-space: nowrap
  width: 124px

.line-31-4
  height: 134px
  margin-left: 1.0px
  margin-top: 23px
  width: 1px

.i-phone-xs-space-graynew-8
  height: 238px
  margin-left: -1.88px
  margin-top: 22px
  object-fit: cover
  width: 109px

.overlap-group-container-4
  height: 476px
  left: 0
  position: absolute
  top: 0
  width: 642px

.overlap-group-126
  height: 439px
  left: 428px
  position: absolute
  top: 0
  width: 214px

.group-1359685
  align-items: center
  display: flex
  flex-direction: column
  left: 116px
  min-height: 82px
  position: absolute
  top: 88px
  width: 97px

.i-phone-ver-1-1
  height: 250px
  left: 99px
  object-fit: cover
  position: absolute
  top: 187px
  width: 115px

.group-1359686
  align-items: center
  display: flex
  flex-direction: column
  left: 0
  min-height: 439px
  position: absolute
  top: 0
  width: 165px

.frame-1091338-4
  align-items: center
  display: flex
  flex-direction: column
  min-height: 157px
  width: 165px

.multiple-user-calling-groups
  letter-spacing: 0
  line-height: 150px
  margin-bottom: -137px
  min-height: 13px
  text-align: center
  white-space: nowrap
  width: 165px

.line-31-5
  height: 122px
  margin-left: 1.0px
  margin-top: 23px
  width: 1px

.i-phone-xs-space-graynew-1
  height: 258px
  margin-right: 18.84px
  margin-top: 24px
  object-fit: cover
  width: 123px

.group-container-17
  height: 471px
  left: 0
  position: absolute
  top: 5px
  width: 499px

.overlap-group1-53
  height: 471px
  left: 0
  position: absolute
  top: 0
  width: 499px

.group-1359687
  align-items: center
  display: flex
  flex-direction: column
  left: 323px
  min-height: 363px
  position: absolute
  top: 83px
  width: 176px

.frame-1091338-5
  align-items: center
  display: flex
  flex-direction: column
  min-height: 53px
  width: 176px

.call-queuing-system
  letter-spacing: 0
  line-height: 150px
  margin-bottom: -133px
  min-height: 17px
  text-align: center
  white-space: nowrap
  width: 176px

.line-31-6
  height: 13px
  margin-left: 1.0px
  margin-top: 23px
  width: 1px

.group-40-1
  height: 277px
  margin-right: 18.12px
  margin-top: 33px
  width: 126px

.group-1359688
  align-items: center
  display: flex
  flex-direction: column
  left: 150px
  min-height: 125px
  padding: 27.9px 0
  position: absolute
  top: 40px
  width: 125px

.unlock-the-door-right-from-the-call
  letter-spacing: 0
  line-height: 150px
  margin-bottom: -120px
  min-height: 30px
  text-align: center
  white-space: nowrap
  width: 148px

.line-31-7
  height: 17px
  margin-left: 1.0px
  margin-top: 23px
  width: 1px

.group-1359774
  align-items: center
  display: flex
  flex-direction: column
  left: 252px
  min-height: 125px
  position: absolute
  top: 0
  width: 125px

.unlock-the-door-from-the-app
  letter-spacing: 0
  line-height: 150px
  margin-bottom: -119px
  min-height: 31px
  text-align: center
  white-space: nowrap
  width: 137px

.line-31-8
  height: 86px
  margin-left: 1.0px
  margin-top: 23px
  width: 1px

.intercom-mobile
  height: 292px
  left: 247px
  position: absolute
  top: 156px
  width: 134px

.group-1359750
  height: 310px
  left: 131px
  position: absolute
  top: 150px
  width: 143px

.group-1359749-3
  background-size: 100% 100%
  height: 338px
  left: 0
  position: absolute
  top: 132px
  width: 156px

.group-1359773
  align-items: center
  display: flex
  flex-direction: column
  left: 16px
  min-height: 125px
  position: absolute
  top: 0
  width: 125px

.video-call-flip-came
  letter-spacing: 0
  line-height: 150px
  margin-bottom: -102px
  min-height: 48px
  text-align: center
  white-space: nowrap
  width: 149px

.line-31-9
  height: 24px
  margin-left: 0.97px
  margin-top: 23px
  width: 1px

