.rectangle-button-3
  align-items: flex-end
  background-size: 100% 100%
  display: flex
  height: 12px
  min-width: 12px
  padding: 3.6px 3.6px
  transform: rotate(-90.00deg)

.fontistokey
  height: 4px
  width: 4px

.rectangle-button-3.rectangle-button-4
  justify-content: flex-end
  margin-left: 0
  margin-top: 4px
  padding: 3.3px 3.3px

.rectangle-button-3.rectangle-button-4 .fontistokey,
.rectangle-button-3.rectangle-button-2 .fontistokey
  height: 5px
  width: 5px

.rectangle-button-3.rectangle-button-1
  margin-left: 0
  margin-top: 4px

.rectangle-button-3.rectangle-button-5
  height: 13px
  min-width: 13px
  padding: 3.9px 3.9px

.rectangle-button-3.rectangle-button-5 .fontistokey,
.rectangle-button-3.rectangle-button-2-1 .fontistokey
  height: 5px
  margin-bottom: 0
  width: 5px

.rectangle-button-3.rectangle-button-2,
.rectangle-button-3.rectangle-button-2-1
  height: 13px
  margin-left: 0
  margin-top: 5px
  min-width: 13px
  padding: 3.9px 3.9px

